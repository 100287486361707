import { getData, postData, patchData, deleteData } from 'Services/apiCall';
import queryString from 'query-string';

export const getBonusDetails = async ({ queryKey: [_key, id] }) => {
    try {
        const response = await getData(`bonus/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const launchBonus = async ({ bonusData }) => {
    try {
        return await postData('bonus', bonusData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const editLaunchedBonus = async ({ bonusData }) => {
    try {
        return await patchData('bonus', bonusData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const deleteBonus = async (bonusId) => {
    try {
        return await deleteData(`bonus/${bonusId}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getBonusList = async ({ queryKey: [_key, { search, status, pageNumber, limit, sort }] }) => {
    try {
        const query = queryString.stringify({
            limit,
            ...(search && { search }),
            ...(status && { status }),
            ...(sort?.key && { sortBy: sort.key }),
            ...(sort?.direction && { sortOrder: sort.direction }),
            page: pageNumber,
        });

        const path = `bonus/activities/all?${query}`;
        const data = await getData(path);
        return data?.data || [];
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getBonusCount = async ({ queryKey: [_key] }) => {
    try {
        const { data } = await getData('bonus/count');
        return data || {};
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
