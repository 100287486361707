import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import '../styles.scss';
import PageToggle from '../components/PageToggle';
import Recognitions from './Recognition';
import Redemptions from './Redemptions';
import Awards from './Awards';
import Bonus from './Bonus';
import { TAB_STATES } from '../constants';
import { PLATFORM } from 'constants.js';

const Index = ({ active, setActive, history, isAdmin, platform, isManager }) => {
    const [key, setKey] = useState(TAB_STATES.RECOGNITION);

    return (
        <div className=' activities-container'>
            <div className=' navigatortabs'>
                <Tabs activeKey={key} onSelect={(_key) => setKey(_key)}>
                    <Tab eventKey={TAB_STATES.RECOGNITION} title={TAB_STATES.RECOGNITION}>
                        <Recognitions history={history} isAdmin={isAdmin} isManager={isManager} />
                    </Tab>
                    <Tab eventKey={TAB_STATES.REDEMPTIONS} title={TAB_STATES.REDEMPTIONS}>
                        <Redemptions history={history} />
                    </Tab>
                    {platform !== PLATFORM.WEB && (
                        <Tab eventKey={TAB_STATES.AWARDS} title={TAB_STATES.AWARDS}>
                            <Awards history={history} />
                        </Tab>
                    )}
                    <Tab eventKey={TAB_STATES.BONUS} title={TAB_STATES.BONUS}>
                        <Bonus history={history} />
                    </Tab>
                </Tabs>
                {isAdmin && <PageToggle active={active} setActive={setActive} />}
            </div>
        </div>
    );
};

Index.propTypes = {
    active: PropTypes.string,
    setActive: PropTypes.func,
    history: PropTypes.object,
    isAdmin: PropTypes.bool,
    platform: PropTypes.string,
    isManager: PropTypes.bool,
};

export default Index;
