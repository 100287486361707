export const AWARD_STATS = (awardDetails) => [
    { value: awardDetails?.nominee, label: 'nominees' },
    { value: awardDetails?.voters, label: 'voters' },
    { value: awardDetails?.votes, label: 'votes received' },
];

export const POST_TYPE = {
    RECOGNITION: 'recognition',
    CELEBRATION: 'celebration',
    BONUS: 'bonus',
    AWARD: 'award',
};

export const CELEBRATION_TYPE = {
    BIRTHDAY: 'birthday',
    WORK_ANNIVERSARY: 'anniversary',
};

export const RECEIVERS_TO_SHOW = 9;

export const POST_APPRECIATION_MESSAGE =
    'Words cannot describe how awesome we feel to have you in our team. Your hard work and commitment are deeply appreciated 🙌';
