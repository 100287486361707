import React from 'react';
import PropTypes from 'prop-types';
import TaskItem from '../TaskItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { shallowEqual, useSelector } from 'react-redux';
import './style.scss';

const AllTasks = ({ history, tasksData, next, hasMore }) => {
    const { nameForPoints } = useSelector(mapStateToProps, shallowEqual);
    const tasksLength = tasksData?.pages[0]?.data?.total;
    return (
        <div>
            {tasksLength > 0 && (
                <div className='recognitions-pending'>
                    <p className='pending'>{tasksLength} pending tasks to complete</p>
                </div>
            )}
            <div className='pb-3'>
                <InfiniteScroll
                    dataLength={tasksData?.pages?.length * 9}
                    next={next}
                    hasMore={hasMore}
                    height={750}
                    loader={<CustomLoader size={10} />}
                >
                    {tasksData?.pages?.map((pageData) =>
                        pageData?.data?.tasks?.map((taskItem) => (
                            <TaskItem
                                taskItemData={taskItem}
                                key={taskItem._id + taskItem?.users?.type}
                                history={history}
                                nameForPoints={nameForPoints}
                            />
                        ))
                    )}
                </InfiniteScroll>
            </div>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    nameForPoints: Workspace.nameForPoints,
});

AllTasks.propTypes = {
    history: PropTypes.object,
    tasksData: PropTypes.object,
    next: PropTypes.func,
    hasMore: PropTypes.bool,
};

export default AllTasks;
