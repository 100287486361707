import React from 'react';
import PropTypes from 'prop-types';

const Stepper = ({ step, loading, onStepChange, steppers }) => {
    return (
        <div className={`create-stepper`}>
            {steppers.map((stepper, index) => (
                <React.Fragment key={stepper}>
                    <button
                        className={`step-button ${step === index ? 'active' : ''}`}
                        onClick={() => (loading ? null : onStepChange(index))}
                    >
                        {stepper}
                    </button>
                    {index < steppers.length - 1 && <div />}
                </React.Fragment>
            ))}
        </div>
    );
};

Stepper.propTypes = {
    step: PropTypes.number,
    loading: PropTypes.bool,
    onStepChange: PropTypes.func,
    steppers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Stepper;
