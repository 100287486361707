import React from 'react';
import PropTypes from 'prop-types';
import { BONUS_STATUS, COMPLETED_TABLE_PROPERTIES, SCHEDULED_TABLE_PROPERTIES } from '../../constants';
import Table from 'components/ReusableComponents/Table';
import { PLATFORM } from 'constants.js';

const Index = ({ tab, rowData, paginationProps, sort, setSort, loading, platform }) => {
    const sortHandler = (index) => {
        setSort({ key: tableProperties.SORT_KEY[index], direction: -(sort.direction || -1) });
    };

    let tableProperties;

    switch (tab) {
        case BONUS_STATUS.SCHEDULED:
            tableProperties = { ...SCHEDULED_TABLE_PROPERTIES };

            if (platform === PLATFORM.WEB) {
                // Remove 'ANNOUNCE ON' from HEADERS
                tableProperties.HEADERS = [...SCHEDULED_TABLE_PROPERTIES.HEADERS];
                tableProperties.HEADERS.splice(5, 1);
            }
            break;
        case BONUS_STATUS.COMPLETED:
            tableProperties = COMPLETED_TABLE_PROPERTIES;
            break;
    }

    return (
        <div className='mt-3'>
            <Table
                header={tableProperties.HEADERS}
                columnWidth={tableProperties.COLUMN_WIDTH}
                noSortColumns={tableProperties.NO_SORT}
                data={rowData}
                paginationProps={paginationProps}
                handleSort={sortHandler}
                loading={loading}
                noShadow
            />
        </div>
    );
};

Index.propTypes = {
    tab: PropTypes.string,
    rowData: PropTypes.array,
    paginationProps: PropTypes.object,
    sort: PropTypes.object,
    setSort: PropTypes.func,
    loading: PropTypes.bool,
    platform: PropTypes.string,
};

export default Index;
