import React from 'react';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { ReactComponent as ViewMoreDetails } from 'Assets/images/more-details.svg';
import StatusChips from '../../components/StatusChips';
import { Tooltip } from 'components';
import MemberTag from '../../components/MemberTag';
import { capitalizeFirstLetter } from 'utils/HelperFunctions';

const getRecievedRows = (tableData, getSenderImageURL, history) => {
    return tableData.map((item) => {
        return {
            id: item._id,
            row: [
                item.sentOn || item.date,
                <MemberTag
                    key={item._id}
                    memberID={item?.sender?.id}
                    memberPhoto={getSenderImageURL(item)}
                    memberName={item?.sender?.name || 'EngageWith'}
                    history={history}
                />,
                item?.values?.join(', ') || '-',
                <p className='recog-type' key={item._id}>
                    <span className='recog-type-name'>{capitalizeFirstLetter(item.recognitionType)}</span>
                    <br></br>
                    <span className='recog-type-points'>{`(${item.points})`}</span>
                </p>,
                <EllipsisText key={item._id} text={item?.message} white maxWidth='300px' />,
            ],
        };
    });
};

const getRowData = (tableData, getSenderImageURL, handleViewMore, history) => {
    return tableData.map((item) => {
        return {
            id: item._id,
            row: [
                item.sentOn || item.date,
                <MemberTag
                    key={item._id}
                    memberID={item?.receiver?.id}
                    memberPhoto={getSenderImageURL(item)}
                    memberName={item?.receiver?.name}
                    history={history}
                />,
                <p className='recog-type' key={item._id}>
                    <span className='recog-type-name'>{capitalizeFirstLetter(item.recognitionType)}</span>
                    <br></br>
                    <span className='recog-type-points'>{`(${item.points})`}</span>
                </p>,
                <EllipsisText key={item._id} text={item?.message} white maxWidth='338px' />,
                <StatusChips key={item._id} status={item?.status} rejectedReason={item?.rejectionReason} />,
                <Tooltip key={item._id} placement='top' title={'View more details'}>
                    <ViewMoreDetails className='pointer-cursor' onClick={() => handleViewMore(item)} />
                </Tooltip>,
            ],
        };
    });
};

const getRejectedRows = (tableData, getSenderImageURL, getReceiverImageURL, handleViewMore, history) => {
    return tableData.map((item) => {
        return {
            id: item._id,
            row: [
                item.sentOn || item.date,
                <MemberTag
                    key={item._id}
                    memberID={item.sender.id}
                    memberPhoto={getSenderImageURL(item)}
                    memberName={item.sender.name || 'EngageWith'}
                    history={history}
                />,
                <MemberTag
                    key={item._id}
                    memberID={item.receiver.id}
                    memberPhoto={getReceiverImageURL(item)}
                    memberName={item.receiver.name}
                    history={history}
                />,
                <p className='recog-type' key={item._id}>
                    <span className='recog-type-name'>{capitalizeFirstLetter(item.recognitionType)}</span>
                    <br></br>
                    <span className='recog-type-points'>{`${item.points}`}</span>
                </p>,
                <EllipsisText key={item._id} text={item.rejectionReason} white maxWidth='300px' />,
                <Tooltip key={item._id} placement='top' title={'View more details'}>
                    <ViewMoreDetails className='pointer-cursor' onClick={() => handleViewMore(item)} />
                </Tooltip>,
            ],
        };
    });
};

export { getRecievedRows, getRejectedRows, getRowData };
