import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/ReusableComponents/InputField';
import EWModal from 'components/ReusableComponents/EWModal';
import clsx from 'clsx';
import { PULSE_ROUTE, AI_PULSE_ERROR_STATES } from '../../../constants';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { generateAIPulse } from 'Services/apiFunctions';
import './styles.scss';
import { useMutation } from 'react-query';

const CreatePulseWithAIModal = ({
    open,
    data,
    history,
    setOpen,
    onAIGeneration,
    existingPrompt,
    existingQuestionCount,
}) => {
    const [userPrompt, setUserPrompt] = useState(existingPrompt || '');
    const [questionCount, setQuestionCount] = useState(existingQuestionCount || 0);
    const [promptError, setPromptError] = useState('');
    const [questionCountError, setQuestionCountError] = useState('');
    const { SetSnackbar } = useToaster();
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (open) {
            setUserPrompt(existingPrompt || '');
            setQuestionCount(existingQuestionCount || '');
        } else {
            clearStates();
        }
    }, [open, existingPrompt, existingQuestionCount]);

    const clearStates = () => {
        if (isMounted.current) {
            setUserPrompt('');
            setQuestionCount('');
            setPromptError('');
            setQuestionCountError('');
        }
    };

    const handlePromptChange = (_id, value) => {
        setUserPrompt(value);
        setPromptError(value.length < 50 ? AI_PULSE_ERROR_STATES.PROMPT_ERROR : '');
    };

    const handleQuestionCountChange = (_id, value) => {
        setQuestionCount(value);
        const parsedCount = parseInt(value, 10);
        setQuestionCountError(
            isNaN(parsedCount) || parsedCount < 1 || parsedCount > 15 ? AI_PULSE_ERROR_STATES.QUESTION_COUNT_ERROR : ''
        );
    };

    const {
        mutateAsync: generatePulseMutation,
        isLoading,
        isFetching,
    } = useMutation({
        mutationFn: generateAIPulse,
    });

    const handleGenerate = async () => {
        if (!promptError && !questionCountError) {
            try {
                const refinedPrompt = userPrompt.trim() + '\n' + `( generate ${questionCount} questions)`;
                const response = await generatePulseMutation({ message: refinedPrompt });
                if (response.ok && isMounted.current) {
                    handleClose();
                    const newAiData = {
                        userPrompt,
                        questionCount,
                        aiPulseData: response.data,
                    };

                    if (onAIGeneration) {
                        onAIGeneration(newAiData);
                    } else {
                        history.push({
                            pathname: PULSE_ROUTE.CREATE,
                            state: {
                                isAIGenerated: true,
                                aiData: newAiData,
                            },
                        });
                    }
                }
            } catch (error) {
                if (isMounted.current) {
                    showSnackBarMessage(SetSnackbar, 'error', error.message);
                }
            }
        }
    };

    const handleClose = () => {
        clearStates();
        setOpen(false);
    };

    useEffect(() => {
        return () => {
            isMounted.current = false;
            handleClose();
        };
    }, []);

    const augmentedData = {
        ...data,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: handleClose,
        rightButtonText: 'Generate',
        handleRightButtonClick: handleGenerate,
        loading: isLoading || isFetching,
        disabled: isLoading || isFetching || !!promptError || !!questionCountError,
    };

    if (!open) {
        return null;
    }

    return (
        <EWModal
            open={open}
            onClose={handleClose}
            width='528px'
            height='500px'
            modalData={augmentedData}
            customClassName='create-ai-pulse-modal'
        >
            <div className='create-ai-pulse-content'>
                <InputField
                    inputClass={clsx('ai-pulse-input', { 'pulse-prompt-input': true })}
                    label={data.promptInput.heading}
                    labelClass='ai-pulse-prompt-label'
                    placeholder={data.promptInput.placeholder}
                    value={userPrompt}
                    handleChange={handlePromptChange}
                    textArea
                />
                {promptError && <span className='ai-pulse-modal-text-error'>{promptError}</span>}
                <InputField
                    inputClass='ai-pulse-prompt-input'
                    label={data.questionCountInput.heading}
                    labelClass='ai-pulse-prompt-label'
                    placeholder={data.questionCountInput.placeholder}
                    value={questionCount}
                    inputType='number'
                    handleChange={handleQuestionCountChange}
                />
                {questionCountError && <span className='ai-pulse-modal-text-error'>{questionCountError}</span>}
            </div>
        </EWModal>
    );
};

CreatePulseWithAIModal.propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        buttonText: PropTypes.string,
        promptInput: PropTypes.shape({
            heading: PropTypes.string,
            placeholder: PropTypes.string,
        }),
        questionCountInput: PropTypes.shape({
            heading: PropTypes.string,
            placeholder: PropTypes.string,
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    setOpen: PropTypes.func.isRequired,
    onAIGeneration: PropTypes.func,
    existingPrompt: PropTypes.string,
    existingQuestionCount: PropTypes.number,
};

CreatePulseWithAIModal.defaultProps = {
    onAIGeneration: null,
    existingPrompt: '',
    existingQuestionCount: null,
};

export default CreatePulseWithAIModal;
