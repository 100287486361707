import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import Table from 'components/ReusableComponents/Table';
import { dateRanges } from 'constants.js';
import { format } from 'date-fns';
import { find, debounce } from 'lodash';
import { GET_BONUS_DATA } from 'Services/apiKeys';
import { getMyBonusActivities } from 'Services/apiFunctions';
import { useQuery } from 'react-query';
import { BONUS_TABLE_PROPERTIES } from './constants';
import { rowFormatter } from './componentUtil';
import { useSelector, shallowEqual } from 'react-redux';
import Certificate from 'components/ReusableComponents/Certificate';
import CustomLoader from 'components/ReusableComponents/CustomLoader';

const Index = ({ history }) => {
    const { userName } = useSelector(mapStateToProps, shallowEqual);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const [certificateInfo, setCertificateInfo] = useState({});

    const [filter, setFilter] = useState({
        page: 1,
        sortBy: 'launchDate',
        sortOrder: -1,
        search: '',
    });

    const handleSearchChange = debounce((value) => {
        setFilter({ ...filter, search: value, page: 1 });
    }, 400);

    const {
        data: apiData,
        isLoading,
        isFetching,
    } = useQuery([GET_BONUS_DATA, filter], getMyBonusActivities, {
        keepPreviousData: true,
    });
    const data = apiData?.data.map(rowFormatter(history, setShowCertificate, setCertificateInfo, userName));

    const handleFilter = (type, value) => {
        if (type === 'date') {
            if (value?.value === 'Custom Date') {
                setShowDatePicker(true);
            } else {
                setFilter({
                    ...filter,
                    page: 1,
                    dateRange: value.value,
                });
            }
        }
    };

    const handleSort = (index) => {
        const type = BONUS_TABLE_PROPERTIES.SORT_KEY[index];
        const tempFilter = { ...filter, sortBy: type };
        if (type === filter.sortBy) {
            tempFilter.order = tempFilter.sortOrder === 1 ? -1 : 1;
        } else {
            tempFilter.order = 1;
        }

        setFilter({ ...filter, page: 1, sortBy: tempFilter.sortBy, sortOrder: tempFilter.order });
    };

    const handleCustomDate = (selectedDate) => {
        const fromDate = format(selectedDate?.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        const toDate = format(selectedDate?.endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        setFilter({
            ...filter,
            page: 1,
            dateRange: { start: fromDate, end: toDate },
        });
        setShowDatePicker(false);
    };

    const dateLabel = () => {
        if (!filter?.dateRange) {
            return 'ALL';
        }
        if (typeof filter.dateRange === 'object') {
            return 'Custom Date';
        } else {
            return find(dateRanges, (item) => item.value === filter.dateRange)?.label;
        }
    };

    const paginationProps = {
        fixedBottom: true,
        currentPage: filter.page,
        totalCount: apiData?.totalCount,
        setCurrentPage: (value) => setFilter({ ...filter, page: value }),
    };

    return (
        <div className='ac-main-container'>
            <div className='ac-filter-container'>
                <div className='d-flex'>
                    <CustomFilterDropdown
                        title='Date Range'
                        filterOptions={dateRanges}
                        selectedName={dateLabel()}
                        optionsSelected
                        handleSelection={(index) => handleFilter('date', dateRanges[index])}
                        dropDownID='dateDropdown'
                        singleSelect
                        buttonStyleClass='width-188'
                    />
                    <CustomDateRangeModal
                        open={showDatePicker}
                        onClose={() => setShowDatePicker(false)}
                        onConfirm={(selectedDate) => handleCustomDate(selectedDate)}
                    />
                </div>
                <div className='mt-3'>
                    <CustomSearchBox
                        width={'320px'}
                        handleSearch={(event) => handleSearchChange(event.target.value)}
                        placeholder='Search Bonus name'
                    />
                </div>
            </div>

            <div className='ac-table-container'>
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    <Table
                        header={BONUS_TABLE_PROPERTIES.HEADERS}
                        noSortColumns={BONUS_TABLE_PROPERTIES.NO_SORT}
                        columnWidth={BONUS_TABLE_PROPERTIES.COLUMN_WIDTH}
                        data={data}
                        noShadow
                        loading={isFetching || isLoading}
                        paginationProps={paginationProps}
                        handleSort={handleSort}
                    />
                )}
            </div>
            <Certificate open={showCertificate} onClose={() => setShowCertificate(false)} {...certificateInfo} />
        </div>
    );
};

const mapStateToProps = ({ User }) => ({
    userName: User.userName,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
