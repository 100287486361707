import React from 'react';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';
import { ROUTES } from 'constants.js';

const Index = ({ history, image, heading, subHeading, buttonText, rightButtonText, handleClick }) => {
    return (
        <div className='container-banner row ml-0'>
            <div className='banner-img'>{image}</div>
            <div className='col-lg-9'>
                <p className='missing-date-count mt-3 mb-0'>{heading}</p>
                <p className='cb-subheading'>{subHeading}</p>
                <div>
                    <button className='ew-btn pb-btn' onClick={handleClick}>
                        {buttonText}
                    </button>
                    {rightButtonText && (
                        <EWButton
                            buttonStyleClass='view-employee-btn'
                            buttonText={rightButtonText}
                            primary={false}
                            border
                            onClick={() => history.push(ROUTES.CELEBRATIONS_EMPLOYEES_WITHOUT_DATES)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    image: PropTypes.element,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    buttonText: PropTypes.string,
    rightButtonText: PropTypes.string,
    handleClick: PropTypes.func,
};

export default Index;
