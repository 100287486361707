import React from 'react';
import { ReactComponent as VoteIcon } from 'Assets/images/vote-icon.svg';
import { ReactComponent as ApproveOrRejectIcon } from 'Assets/images/approveOrReject.svg';
import { ReactComponent as PendingIcon } from 'Assets/images/hourglass.svg';
import NewAward from './employeeAwards/newAward';
import VotingPeriodExpired from './employeeAwards/votingPeriodExpired';
import ApprovalPending from './recognitions/approvalPending';
import RequestingApproval from './recognitions/requestingApproval';

const SLIDES = {
    employee_awards: {
        new_award: {
            ICON: <VoteIcon />,
            COMPONENT: NewAward,
        },
        voting_period_expired: {
            ICON: <VoteIcon />,
            COMPONENT: VotingPeriodExpired,
        },
    },
    recognitions: {
        approval_pending: {
            ICON: <PendingIcon />,
            COMPONENT: ApprovalPending,
        },
        requesting_approval: {
            ICON: <ApproveOrRejectIcon />,
            COMPONENT: RequestingApproval,
        },
    },
};

export const getSlides = (items, history) => {
    const Slides = items?.map((item) => {
        const tempSlide = SLIDES[item.type][item.event];
        return {
            icon: tempSlide.ICON,
            component: <tempSlide.COMPONENT data={item} history={history} />,
        };
    });
    return Slides;
};
