import React from 'react';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Stepper from './Stepper';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const Index = ({ step, onStepChange, onClose, handleNext, buttonText, title, loading, steppers }) => {
    const getButtonText = () => {
        if (step === 0) {
            return 'Next';
        }
        return buttonText;
    };

    return (
        <div className='create-header-container header-container'>
            <div className='header-top'>
                <div>
                    <div className='close-icon-container'>
                        <BackIcon onClick={() => onClose()} data-testid={'back-icon'} />
                    </div>
                    <div className='non-editable-title'>{title}</div>
                </div>
                <EWButton buttonText={getButtonText()} onClick={handleNext} loading={loading} disabled={loading} />
            </div>
            <Stepper step={step} onStepChange={onStepChange} loading={loading} steppers={steppers} />
        </div>
    );
};

Index.propTypes = {
    step: PropTypes.number,
    handleNext: PropTypes.func,
    buttonText: PropTypes.string,
    title: PropTypes.string,
    onStepChange: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    steppers: PropTypes.array,
};

export default Index;
