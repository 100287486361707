import React from 'react';
import './style.scss';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { AUDIENCE_DROPDOWN_TABS } from 'components/Dashboard/Content/PulseSurvey/CreatePulse/constants';

const Index = ({ users, onRemove, email }) => {
    const removeUser = (event, index) => {
        event.stopPropagation();
        const temp = [...users];
        temp.splice(index, 1);
        if (onRemove) {
            onRemove(temp);
        }
    };

    const getName = (value) => {
        if (email) {
            return value.email;
        }
        // show member count for channel or usergroup
        if (value?.channel) {
            return `#${value.name}`;
        } else if (value?.groupID) {
            return `@${value.name}`;
        } else if (value.type === AUDIENCE_DROPDOWN_TABS.managersTeam) {
            return `${value.userName || value.managerName}'s Team`;
        } else if (value.type === AUDIENCE_DROPDOWN_TABS.departments) {
            return `${value.name} department`;
        }
        return value.name || value?.userName;
    };

    return (
        <div>
            {users && users.length > 0 && (
                <div className='add-user-view'>
                    {users.map((user, index) => (
                        <div className='item-user-container' key={user?.id || user?._id || index}>
                            {(user.type === AUDIENCE_DROPDOWN_TABS.user || user.pictureURL) && (
                                <img
                                    className='item-user-image'
                                    src={user?.picUrl || user?.pictureURL || require('Assets/images/defaultUser.png')}
                                    alt='user'
                                />
                            )}
                            <p className={'item-user'}>{getName(user)}</p>
                            {!user?.isEditing && (
                                <CloseIcon className='img' onClick={(event) => removeUser(event, index)} />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    users: PropTypes.array,
    onRemove: PropTypes.func,
    email: PropTypes.bool,
};

export default Index;
