import NewUserAdded from './NewUserAdded';
import WalletReloaded from './WalletReloaded';
import UserDeactivated from './userDeactivated';
import AutoReloadEnabled from './AutoReloadEnabled';
import AutoReloadDisabled from './AutoReloadDisabled';
import RedemptionFailed from './RedemptionFailed';
import SubscriptionExpired from './SubscriptionExpired';
import RedemptionFailedToUser from './RedemptionFailedToUser';
import BonusAwarded from './BonusAwarded';
// all the Notification type components must be a normal function returning jsx

const NotificationItemComponents = {
    onboarding: {
        userAdded: NewUserAdded,
    },
    organisation: {
        userDeactivated: UserDeactivated,
    },
    payments: {
        walletReloaded: WalletReloaded,
        autoReloadEnabled: AutoReloadEnabled,
        autoReloadDisabled: AutoReloadDisabled,
        redemptionFailed: RedemptionFailed,
        subscriptionExpired: SubscriptionExpired,
    },
    redemption: {
        redemptionFailedToUser: RedemptionFailedToUser,
    },
    bonus: {
        bonusAwarded: BonusAwarded,
    },
};

export default NotificationItemComponents;
