import React, { useState } from 'react';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_TEAM_DATA } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import UserFilterList from './UserFilterList';
import { debounce } from 'lodash';
import { useToaster } from 'Context/SnackbarContext';
import { removeSpecialCharacters, showSnackBarMessage } from 'utils/HelperFunctions';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import PropTypes from 'prop-types';
import './style.scss';

const MyTeamTab = ({ selectedUsers, handleUserSelection }) => {
    const { managerId } = useSelector(mapStateToProps, shallowEqual);
    const { SetSnackbar } = useToaster();
    const [search, setSearch] = useState('');

    const {
        data: dropdownData,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery([GET_USERS_TEAM_DATA, { search, managerId }], getUsers, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        enabled: !!managerId,
        keepPreviousData: true,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
        retry: 0,
    });

    const handleSearch = debounce((value) => {
        const updatedValue = removeSpecialCharacters(value);
        setSearch(updatedValue.trim());
    }, 300);

    return (
        <div>
            {search || dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                <div>
                    <CustomSearchBox
                        containerClassName='cr-user-filter-dropdown-search'
                        width='100%'
                        handleSearch={(e) => handleSearch(e.target.value)}
                        placeholder='Search for users in my team'
                        value={search}
                    />
                    <UserFilterList
                        dropdownData={dropdownData}
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                        selectedUsers={selectedUsers}
                        handleUserSelection={(user) => handleUserSelection(user)}
                    />
                </div>
            ) : (
                <div>{(isLoading || isFetching) && <CustomLoader size={10} />}</div>
            )}
        </div>
    );
};

const mapStateToProps = ({ User }) => ({
    managerId: User?.managerId,
});

MyTeamTab.propTypes = {
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
};

export default MyTeamTab;
