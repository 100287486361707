import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getUsers, getDepartments, getManagerList, fetchChannels } from 'Services/apiFunctions';
import { GET_USERS_DATA, GET_PAGINATED_DEPARTMENT, GET_MANAGERS_LIST } from 'Services/apiKeys';
import { useInfiniteQuery, useQuery } from 'react-query';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useToaster } from 'Context/SnackbarContext';
import { removeSpecialCharacters, showSnackBarMessage } from 'utils/HelperFunctions';
import CustomDropdown from 'components/ReusableComponents/CustomDropdown';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import DropdownList from '../DropdownList';
import TabDropdown from '../TabDropdown';
import Tabs from '../Tabs';
import { AUDIENCE_DROPDOWN_TABS_PLACEHOLDER, AUDIENCE_DROPDOWN_TABS } from '../constants';

const DropdownWithTabs = ({ onChange, audience, isEditing }) => {
    const { SetSnackbar } = useToaster();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchUsers, setSearchUsers] = useState('');
    const [debouncedUsers, setDebouncedUsers] = useState('');
    const [searchChannels, setSearchChannels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchChannel, setSearchChannel] = useState('');
    const [channels, setChannels] = useState([]);
    const [searchManagersTeam, setSearchManagersTeam] = useState([]);
    const [managersTeam, setManagersTeam] = useState([]);
    const [searchTeams, setSearchTeams] = useState('');
    const [searchDepartments, setSearchDepartments] = useState('');
    const [debouncedDepartments, setDebouncedDepartments] = useState('');
    const DEFAULT_SEARCH_TEXT = '^.*$';
    const currentPage = 1;
    const limit = 10;

    // for fetching users
    const {
        data: usersData,
        fetchNextPage: fetchNextUsersPage,
        hasNextPage: hasNextUsersPage,
    } = useInfiniteQuery([GET_USERS_DATA, { search: debouncedUsers, team: false }], getUsers, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
        retry: 0,
        staleTime: 5 * 60 * 1000,
    });

    // for fetching managers
    useQuery([GET_MANAGERS_LIST], getManagerList, {
        onSuccess: (data) => {
            setManagersTeam(data.managers || []);
            setSearchManagersTeam(data.managers || []);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    // for fetching departments
    const {
        data: departmentData,
        fetchNextPage: fetchNextDepartmentsPage,
        hasNextPage: hasNextDepartmentsPage,
    } = useInfiniteQuery([GET_PAGINATED_DEPARTMENT, currentPage, limit, debouncedDepartments], getDepartments, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.departments.length >= limit ? pages.length + 1 : undefined;
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
        retry: 0,
    });

    // for fetching and searching channels and usergroups
    const getChannels = async () => {
        try {
            setLoading(true);

            const queryParams = {
                name: DEFAULT_SEARCH_TEXT,
            };
            const queryStringParams = queryString.stringify(queryParams);
            const url = `users/search-user-channel-group?${queryStringParams}`;

            // Call the fetchDataFromAPI function to get the data
            const data = await fetchChannels(url);
            setLoading(false);

            const channels = data.channels.map((user) => ({
                id: user._id,
                channelID: user.channelID,
                channelName: user.channelName,
                name: user.channelName,
                picUrl: '',
                channel: true,
                memberCount: 0,
                isEditingOnetime: false,
            }));

            const userGroups = data.userGroups.map((userGroup) => ({
                id: userGroup._id,
                groupID: userGroup.groupID,
                name: userGroup.groupName,
                picUrl: '',
                memberCount: 0,
                isEditingOnetime: false,
                channelName: userGroup.groupName,
            }));

            const channelsAndUsergroups = [...channels, ...userGroups];
            setChannels(channelsAndUsergroups);
            setSearchChannels(channelsAndUsergroups);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err.message);
        }
    };

    const getChannelsLists = () => {
        setLoading(true);
        getChannels();
    };

    useEffect(() => {
        getChannelsLists();
    }, []);

    const handleTabChange = (tabIndex) => {
        setSelectedTab(tabIndex);
    };

    const handleSearch = (value) => {
        if (selectedTab === 0) {
            const updatedValue = removeSpecialCharacters(value).trim();
            setSearchUsers(updatedValue);
            handleDebouncedSearch(updatedValue, selectedTab);
        } else if (selectedTab === 1) {
            setSearchChannel(value);
            if (value === '') {
                setSearchChannels(channels);
                return;
            }
            const filterBySearch = channels.filter((item) => {
                return item.channelName.toLowerCase().includes(value.toLowerCase());
            });
            setSearchChannels(filterBySearch);
        } else if (selectedTab === 2) {
            setSearchTeams(value);
            if (value === '') {
                setSearchManagersTeam(managersTeam);
                return;
            }
            const filterBySearch = managersTeam.filter((item) => {
                return item.userName.toLowerCase().includes(value.toLowerCase());
            });
            setSearchManagersTeam(filterBySearch);
        } else {
            setSearchDepartments(value);
            handleDebouncedSearch(value, selectedTab);
        }
    };

    const handleDebouncedSearch = useCallback(
        debounce((value, tab) => {
            if (tab === 0) {
                setDebouncedUsers(value);
            } else if (tab === 3) {
                setDebouncedDepartments(value);
            }
        }, 300),
        []
    );

    const getSearchValue = () => {
        switch (selectedTab) {
            case 0:
                return searchUsers;
            case 1:
                return searchChannel;
            case 2:
                return searchTeams;
            default:
                return searchDepartments;
        }
    };

    return (
        <CustomDropdown
            selectedName={'Select Audience'}
            buttonWidth={341}
            dropdownWidth={450}
            dropdownHeight={311}
            open={dropdownOpen}
            setOpen={setDropdownOpen}
        >
            <div className='cohort-dropdown-container'>
                <Tabs handleTabChange={handleTabChange} selectedTab={selectedTab} />
                <CustomSearchBox
                    containerClassName='dropdown-search'
                    width='100%'
                    value={getSearchValue()}
                    handleSearch={(e) => {
                        handleSearch(e.target.value);
                    }}
                    placeholder={AUDIENCE_DROPDOWN_TABS_PLACEHOLDER[selectedTab]}
                />
                {selectedTab === 0 && (
                    <TabDropdown
                        dropdownData={usersData}
                        dropdownType={AUDIENCE_DROPDOWN_TABS.user}
                        fetchNextPage={fetchNextUsersPage}
                        hasNextPage={hasNextUsersPage}
                        audience={audience}
                        isEditing={isEditing}
                        onChange={onChange}
                    />
                )}
                {selectedTab === 1 &&
                    (loading ? (
                        <CustomLoader />
                    ) : (
                        <div className='channels-dropdown'>
                            <DropdownList
                                data={searchChannels}
                                selectedOptions={audience.channelList}
                                handleSelectionType={AUDIENCE_DROPDOWN_TABS.channels}
                                handleSelection={onChange}
                                isEditing={isEditing}
                            />
                        </div>
                    ))}
                {selectedTab === 2 && (
                    <div className='channels-dropdown'>
                        <DropdownList
                            data={searchManagersTeam}
                            selectedOptions={audience.teamsList}
                            handleSelection={onChange}
                            handleSelectionType={AUDIENCE_DROPDOWN_TABS.managersTeam}
                            isEditing={isEditing}
                            selectedTab={selectedTab}
                        />
                    </div>
                )}
                {selectedTab === 3 && (
                    <TabDropdown
                        dropdownData={departmentData}
                        dropdownType={AUDIENCE_DROPDOWN_TABS.departments}
                        fetchNextPage={fetchNextDepartmentsPage}
                        hasNextPage={hasNextDepartmentsPage}
                        audience={audience}
                        onChange={onChange}
                        isEditing={isEditing}
                    />
                )}
            </div>
        </CustomDropdown>
    );
};

DropdownWithTabs.propTypes = {
    onChange: PropTypes.func,
    audience: PropTypes.array,
    isEditing: PropTypes.bool,
};

export default DropdownWithTabs;
