import React from 'react';
import PropTypes from 'prop-types';
import RecognitionHeader from 'components/Dashboard/Content/Recognitions/RecognitionsHome/RecognitionFeed/RecognitionItem/RecognitionHeader';
import RecognitionPost from './RecognitionPost';
import CelebrationPost from './CelebrationPost';
import AwardPost from './AwardPost';
import { POST_TYPE } from '../constants';

const Index = ({ recognitionData, handleGiveAddOnRecognition, setViewAddOnRecognitionsModal }) => {
    return (
        <>
            <RecognitionHeader recognitionData={recognitionData} />
            {(recognitionData?.type === POST_TYPE.CELEBRATION || recognitionData?.type === POST_TYPE.BONUS) && (
                <CelebrationPost recognitionData={recognitionData} />
            )}

            {recognitionData?.type === POST_TYPE.AWARD && <AwardPost recognitionData={recognitionData} />}

            {recognitionData.type === POST_TYPE.RECOGNITION && (
                <RecognitionPost
                    recognitionData={recognitionData}
                    handleGiveAddOnRecognition={handleGiveAddOnRecognition}
                    setViewAddOnRecognitionsModal={setViewAddOnRecognitionsModal}
                />
            )}
        </>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
    handleGiveAddOnRecognition: PropTypes.func,
    setViewAddOnRecognitionsModal: PropTypes.func,
};

export default Index;
