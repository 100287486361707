import React from 'react';
import EWButton from 'components/ReusableComponents/EWButton';
import { ReactComponent as GiftBox } from 'Assets/images/gift-box-red.svg';
import { useSelector, shallowEqual } from 'react-redux';
import { ROUTES } from 'constants.js';
import PropTypes from 'prop-types';
import './styles.scss';

const Index = ({ history }) => {
    const { userCurrency, redeemableBalance } = useSelector(mapStateToProps, shallowEqual);
    return (
        <div className='red-bal-container'>
            <div className='red-bal-content'>
                <p className='red-bal-title'>Redeemable balance</p>
                <p className='points-txt'>
                    {userCurrency} {redeemableBalance}
                </p>
                <EWButton
                    border
                    primary={false}
                    height='36px'
                    width='128px'
                    onClick={() => history.push(ROUTES.REDEEM)}
                >
                    Redeem now
                </EWButton>
            </div>
            <GiftBox />
        </div>
    );
};

const mapStateToProps = ({ User }) => ({
    redeemableBalance: User.combinedRedeemBalance,
    userCurrency: User.userCurrency,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
