import React from 'react';
import SideOverlay from 'components/ReusableComponents/SideOverlay';
import RecognizedUsers from '../RecognitionItem/RecognizedUsers';
import RecognitionMessage from '../RecognitionItem/RecognitionMessage';
import Recognizer from '../RecognitionItem/Recognizer';
import RecognitionDate from '../RecognitionItem/RecognitionDate';
import AddOnRecognitionsList from './AddOnRecognitionsList';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ open, onClose, recognitionData, handleGiveAddOnRecognition }) => {
    return (
        <SideOverlay open={open} onClose={onClose} height='100%' heading='Recognition'>
            <div className='container-add-on-recognitions'>
                <div className='cao-recognitions-header'>
                    <RecognitionDate date={recognitionData?.createdAt} />
                    <div style={{ marginTop: '8px' }} className='d-flex align-items-start'>
                        <Recognizer recognitionData={{ ...recognitionData }} />
                    </div>
                </div>
                <RecognizedUsers
                    showValue={true}
                    users={recognitionData?.receivers}
                    value={recognitionData?.recognition.companyValues}
                />
                <RecognitionMessage>
                    <RecognitionMessage.Title
                        sender={recognitionData.sender}
                        channel={recognitionData.channel}
                        messageLink={recognitionData.recognition.messageLink}
                    />
                    <RecognitionMessage.Content
                        message={recognitionData.recognition.message}
                        companyValues={recognitionData.recognition.companyValues}
                        showAddOnValue
                    />
                    <RecognitionMessage.AddOnButton
                        handleGiveAddOnRecognition={handleGiveAddOnRecognition}
                        recognitionData={recognitionData}
                    />
                    <RecognitionMessage.Divider
                        addOnRecognitionsCount={recognitionData.recognition.addOnRecognitions.length}
                    />
                </RecognitionMessage>
                <AddOnRecognitionsList recognitionData={recognitionData} />
            </div>
        </SideOverlay>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    recognitionData: PropTypes.object.isRequired,
    handleGiveAddOnRecognition: PropTypes.func,
};

export default Index;
