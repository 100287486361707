import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import BonusForm from './BonusForm';
import './style.scss';
import PropTypes from 'prop-types';

const Index = ({ bonusDetails, setBonusDetails, editBonusId }) => {
    const { workspaceCountry, workspaceCurrency } = useSelector(mapStateToProps, shallowEqual);

    return (
        <div className='container-bonus-details'>
            <div className='cad-left-container'>
                <h5>Bonus Details</h5>
                <BonusForm
                    bonusDetails={bonusDetails}
                    setBonusDetails={setBonusDetails}
                    workspaceCountry={workspaceCountry}
                    editBonusId={editBonusId}
                    workspaceCurrency={workspaceCurrency}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    workspaceCountry: Workspace.workspaceCountry,
    workspaceCurrency: Workspace.workspaceCurrency,
});

Index.propTypes = {
    bonusDetails: PropTypes.object,
    setBonusDetails: PropTypes.func,
    editBonusId: PropTypes.bool,
};

export default Index;
