import React, { useRef, useState } from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserButton from 'components/ReusableComponents/UserButton';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import EWModal from 'components/ReusableComponents/EWModal';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomDropdown from 'components/ReusableComponents/CustomDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import InputField from 'components/ReusableComponents/InputField';
import { CustomCheckbox } from 'components';
import { useToaster } from 'Context/SnackbarContext';
import { debounce, find, intersection } from 'lodash';
import { removeSpecialCharacters, showSnackBarMessage } from 'utils/HelperFunctions';
import { handleSameTeamFeedback } from '../../componentsUtil';
import { GET_USERS_DATA } from 'Services/apiKeys';
import { getUsers } from 'Services/apiFunctions';
import { sendFeedback } from '../../Services/apiFunctions';
import ErrorField from 'components/ReusableComponents/ErrorField';
import { getOptions, TEAM } from '../../constants';
import { FEEDBACK_TEXT_LENGTH_LIMIT } from 'constants.js';
import './style.scss';
import EnhanceWithAI from 'components/ReusableComponents/EnhanceWithAI';

const Index = ({ open, onSuccess, onClose, categories, history }) => {
    const { SetSnackbar } = useToaster();
    const { feedbackConfigs, managerId, userId } = useSelector(mapStateToProps, shallowEqual);
    const [search, setSearch] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [anonymous, setAnonymous] = useState(false);
    const [error, setError] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState();
    const [data, setData] = useState();
    const [strengths, setStrengths] = useState(getOptions(categories));
    const [improvements, setImprovements] = useState(getOptions(categories));
    const selectedStrengths = useRef([]);
    const selectedImprovements = useRef([]);
    const [isAiEnhanced, setIsAiEnhanced] = useState(false);

    const selectedStrengthCount =
        selectedStrengths?.current?.length > 0 ? selectedStrengths.current.toString() : 'Select an option';
    const selectedImprovementCount =
        selectedImprovements?.current?.length > 0 ? selectedImprovements.current.toString() : 'Select an option';

    const { fetchNextPage, hasNextPage, isLoading, isFetching } = useInfiniteQuery(
        [
            GET_USERS_DATA,
            { search, isActive: true, managerId: feedbackConfigs?.feedbackLevel === TEAM ? managerId : null },
        ],
        getUsers,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: (data) => handleSameTeamFeedback(data, feedbackConfigs, setData, SetSnackbar),
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
            retry: 0,
        }
    );

    const { mutate: sendFeedbackMessage, isLoading: isSendingMessage } = useMutation(sendFeedback, {
        onSuccess: (data) => {
            onSuccess(data?.messageUrl, selectedUser?.userName, anonymous);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    /**
     * Handle user search
     */
    const handleSearch = debounce((value) => {
        const updatedValue = removeSpecialCharacters(value);
        setSearch(updatedValue.trim());
    }, 300);

    const handleUserSelection = (user) => {
        setSelectedUser(user);
        setDropdownOpen(false);
    };

    /**
     * Checks if selected strengths and improvements have same attributes
     * @returns true if found same attributes
     */
    const checkForCommonAttributes = () => {
        const commonAttributes = intersection(selectedStrengths.current, selectedImprovements.current);
        setError(commonAttributes.length > 0);
        return commonAttributes.length > 0;
    };

    const commonAttributes = intersection(selectedStrengths.current, selectedImprovements.current);
    const ERROR_TEXT = `${commonAttributes.join(',')}  ${
        commonAttributes.length === 1 ? 'is' : 'are'
    } selected as both strength and improvement`;
    /**
     * Handles sending the feedback message
     */
    const handleSendFeedback = () => {
        if (feedbackMessage.length > FEEDBACK_TEXT_LENGTH_LIMIT) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                `Message length should be less than ${FEEDBACK_TEXT_LENGTH_LIMIT} characters`
            );
            return;
        }

        if (checkForCommonAttributes()) {
            return;
        }

        // Show error on trying to send feedback to self
        if (userId === selectedUser?._id) {
            showSnackBarMessage(SetSnackbar, 'error', 'Cannot send feedback to self');
            return;
        }
        const apiData = {
            receiver: selectedUser?._id,
            strength: selectedStrengths.current,
            improvement: selectedImprovements.current,
            message: feedbackMessage,
            isAIEnhanced: isAiEnhanced,
            anonymous,
        };

        sendFeedbackMessage({ apiData });
    };

    // Data for send feedback modal
    const modalData = {
        heading: '360º Feedback',
        subHeading: 'Share constructive and actionable feedback with your co-workers',
        rightButtonText: anonymous ? 'Send anonymously' : 'Send',
        handleRightButtonClick: handleSendFeedback,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        loading: isSendingMessage,
        disabled: !feedbackMessage || !selectedUser?._id,
    };

    const handleStrengthsSelection = (index) => {
        const temp = [...strengths];
        temp[index].isChecked = !temp[index].isChecked;
        selectedStrengths.current = temp.filter((value) => value.isChecked).map((value) => value.label);
        setStrengths(temp);
        checkForCommonAttributes();
    };

    const handleImprovementsSelection = (index) => {
        const temp = [...improvements];
        temp[index].isChecked = !temp[index].isChecked;
        selectedImprovements.current = temp.filter((value) => value.isChecked).map((value) => value.label);
        setImprovements(temp);
        checkForCommonAttributes();
    };

    if (!open) {
        return null;
    }

    return (
        <div>
            <EWModal open={open} onClose={onClose} width='528px' modalData={modalData}>
                <div className='container-send-feedback'>
                    <div className='csf-user-dropdown'>
                        <CustomDropdown
                            dropDownID='feedback_for'
                            title='Who is this feedback for?'
                            selectedName={selectedUser?.userName || 'Select a user'}
                            buttonWidth={480}
                            dropdownWidth={480}
                            open={dropdownOpen}
                            setOpen={setDropdownOpen}
                            optionsSelected={!!selectedUser?.userName}
                        >
                            <div className='infinite-scroll-container'>
                                <CustomSearchBox
                                    width='473px'
                                    handleSearch={(e) => handleSearch(e.target.value)}
                                    placeholder={'Search'}
                                    value={search}
                                />
                                {isLoading || isFetching ? (
                                    <CustomLoader size={10} />
                                ) : (
                                    data?.pages[0]?.data?.users?.length > 0 && (
                                        <InfiniteScroll
                                            dataLength={data?.pages?.length * 9}
                                            next={fetchNextPage}
                                            hasMore={hasNextPage}
                                            height={192}
                                            loader={<CustomLoader size={10} />}
                                        >
                                            <div>
                                                {data?.pages?.map((pageData) =>
                                                    pageData?.data?.users
                                                        .filter((user) => user?._id !== userId)
                                                        .map((user) => (
                                                            <div className='csf-list-item' key={user?._id}>
                                                                <UserButton
                                                                    id={user?._id}
                                                                    showImage={true}
                                                                    onClick={() => handleUserSelection(user)}
                                                                    imageURL={
                                                                        user?.pictureURL ||
                                                                        require('Assets/images/defaultUser.png')
                                                                    }
                                                                    senderName={user?.userName}
                                                                    customClassname='csf-user'
                                                                />
                                                            </div>
                                                        ))
                                                )}
                                            </div>
                                        </InfiniteScroll>
                                    )
                                )}
                            </div>
                        </CustomDropdown>
                    </div>
                    {strengths?.length > 0 && (
                        <CustomFilterDropdown
                            dropDownID='feedback_strength'
                            title='What are their strengths (optional)'
                            titleStyleClass='csf-dropdown-heading'
                            buttonStyleClass='mr-0'
                            filterOptions={strengths}
                            selectedName={selectedStrengthCount}
                            optionsSelected={!!selectedStrengths.current.length}
                            handleSelection={(index) => handleStrengthsSelection(index)}
                            dropdownWidth='475px'
                            search
                        />
                    )}
                    {improvements?.length > 0 && (
                        <div className='mt-3'>
                            <CustomFilterDropdown
                                dropDownID='feedback_improve'
                                title='Where can they improve? (optional)'
                                titleStyleClass='csf-dropdown-heading'
                                filterOptions={improvements}
                                selectedName={selectedImprovementCount}
                                optionsSelected={!!selectedImprovements.current.length}
                                handleSelection={(index) => handleImprovementsSelection(index)}
                                buttonStyleClass={`mr-0 ${error ? 'border-red' : ''}`}
                                dropdownWidth='475px'
                                search
                            />
                            {error && <ErrorField errorText={ERROR_TEXT} className='mt-1' />}
                        </div>
                    )}
                    <InputField
                        inputID='feedback_message'
                        label='Feedback message'
                        labelClass='csf-dropdown-heading mt-3'
                        value={feedbackMessage}
                        placeholder='When providing feedback, please consider including aspects of the work that you find effective, any areas you believe could be refined, and suggestions for collaborative growth. Constructive insights are always welcome.'
                        width='480px'
                        height='95px'
                        textArea
                        handleChange={(_id, value) => setFeedbackMessage(value)}
                    />
                    <EnhanceWithAI
                        textMessage={feedbackMessage}
                        onMessageEnhanced={(message) => {
                            setFeedbackMessage(message);
                            setIsAiEnhanced(true);
                        }}
                        handleRevertOriginalMessage={(message) => {
                            setFeedbackMessage(message);
                            setIsAiEnhanced(false);
                        }}
                        isAiEnhanced={isAiEnhanced}
                    />
                    {feedbackConfigs?.allowAnonymous && (
                        <div className='csf-config'>
                            <div>Configuration (optional)</div>
                            <div className='csf-checkbox'>
                                <CustomCheckbox checked={anonymous} onClick={() => setAnonymous(!anonymous)} />
                                Anonymously
                            </div>
                            <div>Your feedback will be shared anonymously.</div>
                        </div>
                    )}
                </div>
            </EWModal>
        </div>
    );
};

const mapStateToProps = ({ Workspace, User }) => ({
    feedbackConfigs: Workspace.feedback,
    managerId: User?.managerId,
    userId: User?._id,
});

Index.propTypes = {
    open: PropTypes.bool,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    categories: PropTypes.array,
    history: PropTypes.object,
};

export default Index;
