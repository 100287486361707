import React, { useState } from 'react';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddOnRecognitionsListItem from './AddOnRecognitionsListItem';
import { makePlural } from 'utils/HelperFunctions';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ recognitionData }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const addOnRecognitionCount = recognitionData.recognition.addOnRecognitions.length || 0;
    const addOnRecognitionsList = recognitionData.recognition.addOnRecognitions;

    return (
        <div className='container-add-on-recognitions-list'>
            <button className='cao-recognitions-list-header' onClick={() => setIsExpanded(!isExpanded)}>
                {`${makePlural(addOnRecognitionCount, 'Add on recognition')}`}{' '}
                <ArrowDownIcon
                    style={{
                        transform: !isExpanded ? 'rotate(-180deg)' : '',
                        transition: 'transform 250ms ease',
                    }}
                />
            </button>
            <div className={`cao-recognitions-content ${isExpanded ? '' : 'collapsed'}`}>
                {addOnRecognitionsList.length > 0 &&
                    addOnRecognitionsList.map((addOnItem) => (
                        <AddOnRecognitionsListItem
                            key={addOnItem._id}
                            addOnRecognitionData={{
                                ...addOnItem,
                                type: recognitionData.type,
                            }}
                        />
                    ))}
            </div>
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
};

export default Index;
