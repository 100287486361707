import React, { useState } from 'react';
import ViewAddOnRecognitionModal from '../../RecognitionFeed/ViewAddOnRecognitionModal';
import Posts from 'components/Dashboard/Content/Home/Posts';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ recognitionData, handleGiveAddOnRecognition }) => {
    const [viewAddOnRecognitionsModal, setViewAddOnRecognitionsModal] = useState(false);

    return (
        <div className='container-recognition-item'>
            <Posts
                recognitionData={recognitionData}
                handleGiveAddOnRecognition={handleGiveAddOnRecognition}
                setViewAddOnRecognitionsModal={setViewAddOnRecognitionsModal}
            />

            {viewAddOnRecognitionsModal && (
                <ViewAddOnRecognitionModal
                    open={viewAddOnRecognitionsModal}
                    onClose={() => setViewAddOnRecognitionsModal(false)}
                    recognitionData={recognitionData}
                    handleGiveAddOnRecognition={() => handleGiveAddOnRecognition(recognitionData)}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
    handleGiveAddOnRecognition: PropTypes.func,
};

export default Index;
