import React from 'react';
import UserTag from '../UserTag';
import PropTypes from 'prop-types';
import { makePlural } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ showValue = true, isApproveOrRejectModal, users, value }) => {
    return (
        <div className='container-recognized-users'>
            <div className='container-recognized-users-bg'>
                <div className='recognized-users'>
                    {users.map((user) => (
                        <UserTag key={user._id} imageURL={user?.pictureURL} userName={user?.userName} />
                    ))}
                </div>
                {showValue && value?.length > 0 && (
                    <p className='recognized-users-value'>
                        {`${makePlural(value.length, 'Value', true)}`}
                        highlighted: {isApproveOrRejectModal && <br />}
                        <strong>
                            {value?.length > 0
                                ? value.map((item, index) => (
                                      <span key={item}>
                                          {index > 0 && <span className='values-separator' />}
                                          {item}
                                      </span>
                                  ))
                                : '-'}
                        </strong>
                    </p>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    showValue: PropTypes.bool,
    isApproveOrRejectModal: PropTypes.bool,
    users: PropTypes.array,
    value: PropTypes.string,
};

export default Index;
