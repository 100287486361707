import React from 'react';
import Recognizer from '../../../RecognitionItem/Recognizer';
import RecognitionDate from '../../../RecognitionItem/RecognitionDate';
import RecognizedUsers from '../../../RecognitionItem/RecognizedUsers';
import RecognitionMessage from '../../../RecognitionItem/RecognitionMessage';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ addOnRecognitionData }) => {
    return (
        <div className='container-add-on-recognitions-list-item'>
            <RecognitionDate date={addOnRecognitionData?.createdAt} />
            <div style={{ marginTop: '12px' }}>
                <Recognizer recognitionData={addOnRecognitionData} isAddOn={true} />
            </div>
            <RecognizedUsers
                showValue={true}
                users={addOnRecognitionData?.receivers}
                value={addOnRecognitionData?.values}
            />
            <RecognitionMessage>
                <RecognitionMessage.Content
                    message={addOnRecognitionData.message}
                    companyValues={addOnRecognitionData.companyValues}
                    showViewMoreButtonForMessage
                    showAddOnValue
                />
            </RecognitionMessage>

            <div className='cao-recognitions-divider' />
        </div>
    );
};

Index.propTypes = {
    addOnRecognitionData: PropTypes.object.isRequired,
};

export default Index;
