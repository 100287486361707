import React from 'react';
import Recognizer from '../Recognizer';
import RecognitionDate from '../RecognitionDate';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ recognitionData }) => {
    return (
        <div className='recognition-header'>
            <Recognizer recognitionData={recognitionData} />
            <RecognitionDate date={recognitionData.createdAt} />
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
};

export default Index;
