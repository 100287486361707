import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { AWARD_STATS } from '../../constants';
import './style.scss';

const Index = ({ recognitionData }) => {
    const awardStats = AWARD_STATS(recognitionData?.employeeAward);
    const showAwardStats = () => {
        return (
            recognitionData?.employeeAward?.nominee >= 0 &&
            recognitionData?.employeeAward?.votes >= 0 &&
            recognitionData?.employeeAward?.voters >= 0
        );
    };

    return (
        <div className='post-container'>
            <div className='award-details'>
                <img src={recognitionData?.employeeAward?.icon} alt='award-icon' className='award-icon' />
                <p className='award-name'>
                    {recognitionData?.employeeAward?.awardName.length > 65 ? (
                        <EllipsisText text={recognitionData?.employeeAward.awardName} maxWidth='230px' />
                    ) : (
                        <>{recognitionData?.employeeAward.awardName}</>
                    )}
                </p>
            </div>

            <div className='receivers-details'>
                <p className='congrats-text'>Congratulations!</p>
                <div className='receivers-container'>
                    {recognitionData?.receivers.map((receiver) => (
                        <div key={receiver._id} className='reciever'>
                            <img
                                src={receiver.pictureURL || require('Assets/images/defaultUser.png')}
                                alt=''
                                className='receiver-image'
                            />
                            <p
                                className={clsx({
                                    'multiple-receiver-names': recognitionData?.receivers.length > 1,
                                    'single-receiver-name': recognitionData?.receivers.length === 1,
                                })}
                            >
                                {receiver.userName}
                            </p>
                        </div>
                    ))}
                </div>

                {recognitionData?.receivers.length === 1 && showAwardStats() && (
                    <div className='stats-container'>
                        {awardStats?.map((stat, index) => (
                            <div className='stat' key={index + stat}>
                                <p className='stat-amount'>{stat.value}</p>
                                <p className='stat-detail'>{stat.label}</p>
                            </div>
                        ))}
                    </div>
                )}

                {recognitionData?.message && (
                    <div className='message-container'>
                        <p>{recognitionData?.message}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
};

export default Index;
