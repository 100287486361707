import React from 'react';
import NotificationWrapper from '../../NotificationWrapper';
import PropTypes from 'prop-types';
import { ReactComponent as BonusNotificationIcon } from 'Assets/images/bonus-notification-icon.svg';
import '../styles.scss';

const Index = ({ data }) => {
    const bonusMessage = data.metadata.bonusResult.message;
    return (
        <div className='notification-event-container'>
            <div className=' auto-reload-disabled'>
                <div className='notification-redemption-failed-container'>
                    <div className='notification-redemption-failed-to-user-text-top-container'>
                        <BonusNotificationIcon width={23} height={23} />
                        <p className='notification-module-text'>Bonus: </p>
                        <p>
                            Surprise, surprise.. Its Party time! You’ve been awarded a <b>bonus</b> from the Admins.
                        </p>
                    </div>
                    <p className='notification-event-data-text'>
                        <b>Message:</b> {bonusMessage}
                    </p>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object,
};

export default NotificationWrapper(Index);
