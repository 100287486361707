import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as GoPrevIcon } from 'Assets/images/chevron-left-transparent.svg';
import { ReactComponent as GoNextIcon } from 'Assets/images/chevron-right-transparent.svg';
import './styles.scss';

const Carousel = ({ tasks }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideDirection, setSlideDirection] = useState('');
    const [isAnimating, setIsAnimating] = useState(false);

    const isFirstSlide = currentSlide === 0;
    const isLastSlide = currentSlide === tasks.length - 1;

    useEffect(() => {
        if (isAnimating) {
            const timer = setTimeout(() => {
                setIsAnimating(false);
                setSlideDirection('');
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [isAnimating]);

    const handlePrev = () => {
        if (!isFirstSlide && !isAnimating) {
            setSlideDirection('slide-in-right');
            setIsAnimating(true);
            setCurrentSlide((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (!isLastSlide && !isAnimating) {
            setSlideDirection('slide-in-left');
            setIsAnimating(true);
            setCurrentSlide((prev) => prev + 1);
        }
    };

    return (
        <div className='carousel-content-wrapper'>
            <div className='carousel-header'>
                {tasks[currentSlide].icon}
                <div className='carousel-buttons'>
                    <button
                        onClick={handlePrev}
                        disabled={isFirstSlide || isAnimating}
                        className={`carousel-nav-button ${isFirstSlide || isAnimating ? 'disabled' : ''}`}
                    >
                        <GoPrevIcon />
                    </button>
                    <p>
                        {currentSlide + 1} of {tasks.length}
                    </p>
                    <button
                        onClick={handleNext}
                        disabled={isLastSlide || isAnimating}
                        className={`carousel-nav-button ${isLastSlide || isAnimating ? 'disabled' : ''}`}
                    >
                        <GoNextIcon />
                    </button>
                </div>
            </div>
            <div className='carousel-content'>
                <div className={`carousel-slide ${slideDirection}`}>{tasks[currentSlide].component}</div>
            </div>
        </div>
    );
};

Carousel.propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Carousel;
