import React from 'react';
import PropTypes from 'prop-types';
import CustomRadioButton from 'components/Styles/CustomRadioButton';

const AnouncementRadioSection = ({ bonusDetails, setBonusDetails }) => {
    return (
        <div className='bonus-radio-box mt-4'>
            <h5 className='bonus-radio-header'>Include prize value with announcement message</h5>
            <div className='d-flex flex-row'>
                <CustomRadioButton
                    checked={bonusDetails.addAmountInMessage}
                    onChange={() =>
                        setBonusDetails((prevDetails) => ({
                            ...prevDetails,
                            addAmountInMessage: true,
                        }))
                    }
                    title='Yes, include.'
                    name='include'
                    size={24}
                    bold={bonusDetails.addAmountInMessage}
                />

                <CustomRadioButton
                    checked={!bonusDetails.addAmountInMessage}
                    onChange={() =>
                        setBonusDetails((prevDetails) => ({
                            ...prevDetails,
                            addAmountInMessage: false,
                        }))
                    }
                    title='No, dont include'
                    name='not-include'
                    size={24}
                    bold={!bonusDetails.addAmountInMessage}
                />
            </div>
        </div>
    );
};

AnouncementRadioSection.propTypes = {
    bonusDetails: PropTypes.object,
    setBonusDetails: PropTypes.func,
};

export default AnouncementRadioSection;
