import React from 'react';
import { format, parseISO } from 'date-fns';
import MemberTag from '../../components/MemberTag';
import { ReactComponent as CertificateIcon } from 'Assets/images/file-certificate.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import EllipsisText from 'components/ReusableComponents/EllipsisText';

export const rowFormatter = (history, setShowCertificate, setCertificateInfo, userName) => (award) => ({
    id: award._id,
    row: [
        <strong key={award._id}>
            <EllipsisText text={award.name} />
        </strong>,
        award.launchDate ? format(parseISO(award.launchDate), 'MMM dd, yyyy') : '-',
        award.defaultAmount ? `${award.defaultCurrency} ${award.defaultAmount}` : 'N/A',
        award.awardedBy ? (
            <MemberTag
                key={award._id}
                history={history}
                memberID={award.awardedBy._id}
                memberPhoto={award.awardedBy.pictureURL}
                memberName={award.awardedBy.userName}
            />
        ) : (
            'N/A'
        ),
        <div key={award._id} className='d-flex align-items-center width-100'>
            <EWButton
                plainTextButton={true}
                primary={false}
                className='d-flex align-items-center'
                onClick={() => {
                    setShowCertificate(true);
                    setCertificateInfo({
                        awardee: userName,
                        awardName: award.name,
                        awardDate: format(parseISO(award.launchDate), 'MMM dd, yyyy'),
                    });
                }}
            >
                <CertificateIcon />
                <span className='ml-2'>View Certificate</span>
            </EWButton>
        </div>,
    ],
});

export const getSortHandler = (sort, setSort, tableProperties) => (index) =>
    setSort({ key: tableProperties.SORT_KEY[index], direction: -(sort.direction || -1) });
