import { getData, patchData } from 'Services/apiCall';
import { find } from 'lodash';
import { GET_CORE_VALUES, GET_CONFIG_POINTS } from 'Services/apiKeys';
import { VALUES_LENGTH_LIMIT } from 'constants.js';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { getUserProfile } from 'redux/user/actions';
/*------------------- Below are the functions/handlers used for "Recognition Setup" Component ------------------------*/

export const handlePointsUpdate = ({
    value,
    SetSnackbar,
    setRecognitionPoints,
    setModifiedFields,
    recognitionData,
}) => {
    const regName = /^[0-9\b]*$/;
    if (regName.test(value) || value === '') {
        if (value && parseInt(value) > 99999) {
            SetSnackbar({
                open: true,
                variant: 'warning',
                message: `Maximum limit for Point value is 99999`,
            });
        } else {
            setRecognitionPoints(value);
            setModifiedFields((prevState) => ({
                ...prevState,
                points: parseInt(value) !== parseInt(recognitionData.points),
            }));
        }
    }
};

export const handleApprovalChange = ({ index, rewards, setRewards, rewardsData }) => {
    const tempRewards = [...rewards];
    const value = tempRewards[index].isApprovalRequired;
    tempRewards[index].isApprovalRequired = !value;
    tempRewards[index].modified = !value !== rewardsData[index].isApprovalRequired;
    setRewards(tempRewards);
};

/*------------------- Below are the functions/handlers used for "Balance" Component ------------------------*/

export const handlePointsChange = ({ value, balance, setBalance, nameForPoints, SetSnackbar }) => {
    const re = /^\d*$/;
    if (value === '' || re.test(value)) {
        if (value && parseInt(value) > 99999) {
            SetSnackbar({
                open: true,
                variant: 'warning',
                message: `Maximum limit for initial ${nameForPoints} balance is 99999`,
            });
        } else {
            setBalance({ ...balance, creditPoints: +value });
        }
    }
};

export const handleCarryForward = ({ balance, setBalance }) => {
    setBalance({ ...balance, carryForward: !balance.carryForward });
};

export const handleCarryAmount = ({ value, balance, setBalance, nameForPoints, SetSnackbar }) => {
    const re = /^\d*$/;
    if (value === '' || re.test(value)) {
        if (value && parseInt(value) > 99999) {
            SetSnackbar({
                open: true,
                variant: 'warning',
                message: `Limit for maximum cap for ${nameForPoints} is 99999`,
            });
        } else {
            setBalance({ ...balance, maxPoints: +value });
        }
    }
};

/*------------------- Below are the functions/handlers used for "Balance" Component ------------------------*/

export const handleAddValue = ({ values, setAddModal, SetSnackbar }) => {
    if (values.length > 9) {
        SetSnackbar({
            open: true,
            variant: 'warning',
            message: 'A maximum of 10 values allowed',
        });
    } else {
        setAddModal({ open: true, value: '', index: -1 });
    }
};

export const handleValueDelete = ({ open, index, setDeleteModal }) => {
    setDeleteModal({ open: open, index });
};

export const handleValueEdit = ({ open, index, value, setAddModal }) => {
    setAddModal({
        open: open,
        value: value.value,
        index,
    });
};

export const handleAddEditValueClick = async ({
    addModal,
    value,
    values,
    onClose,
    addValue,
    updateValue,
    queryClient,
    SetSnackbar,
}) => {
    try {
        if (find(values, (item) => item.value.toLowerCase().trim() === value.toLowerCase().trim())) {
            showSnackBarMessage(SetSnackbar, 'warning', 'Value already present!');
        } else if (addModal.value) {
            const updatedValue = {
                value: value.trim(),
                id: values[addModal.index].id,
            };
            await updateValue({ apiData: updatedValue });
            queryClient.setQueryData([GET_CORE_VALUES], (oldData) => {
                const tempData = { ...oldData };
                tempData.values[addModal.index].value = updatedValue.value;
                return tempData;
            });
            showSnackBarMessage(SetSnackbar, 'success', 'Values saved successfully!');
            onClose();
        } else {
            await addValue({ apiData: { value: value.trim() } });
        }
    } catch (err) {
        showSnackBarMessage(SetSnackbar, 'error', err?.message);
    }
};

export const handleCoreValueChange = ({ targetValue, setValue, SetSnackbar, valuesText, setValuesText }) => {
    const regexTest = /[*#@]+/;
    if (targetValue?.split(regexTest)?.length === 1) {
        if (targetValue.length <= VALUES_LENGTH_LIMIT) {
            setValue(targetValue);
            setValuesText({ ...valuesText, charsLeft: getCharsLeft(VALUES_LENGTH_LIMIT, targetValue.length) });
        }
    } else {
        showSnackBarMessage(SetSnackbar, 'warning', 'Value cannot be blank and not contain *, @ and #');
    }
};

/*------------------- Below are the functions/handlers used for "Notifications" Component ------------------------*/

export const getAllNotifications = async (platform, nameForPoints) => {
    try {
        const response = await getData(`recognition/configure/notifications`);
        const nudges = [
            {
                heading: 'Email Notifications',
                subtext: 'Enable Email notifications for all recognitions, approvals, etc.',
                isEnabled: response?.data?.allowEmailNotifications,
                key: 'email',
            },
            // {
            //     heading: 'Summary reports',
            //     subtext: 'Get a weekly report of how your organization uses EngageWith.',
            //     isEnabled: response?.data?.summaryNudge,
            //     key: 'summary',
            // },
            // {
            //     heading: `'Top 3' reports`,
            //     subtext:
            //         'Get a weekly report of top 3 Recognizers, Recognized teammates and\ncompany values highlighted.',
            //     isEnabled: response?.data?.top3Nudge,
            //     key: 'top3',
            // },
            // {
            //     heading: 'Recognition reminders',
            //     subtext:
            //         'Active users will get occasional reminders to recognize their teammates and\ntry new features.',
            //     isEnabled: response?.data?.recognitionNudge,
            //     key: 'recognition',
            // },
            // {
            //     heading: 'Redeem reminders',
            //     subtext: `Active users will get occasional reminders to redeem their ${nameForPoints}.`,
            //     isEnabled: response?.data?.redeemNudge,
            //     key: 'redeem',
            // },
        ];
        return nudges;
    } catch (err) {
        throw new Error(err);
    }
};

/*------------------- Below are the functions/handlers used for "Workspace" tab ------------------------*/

export const getDefaultChannelName = (channel, isMsteams, selectChannel) => {
    if (channel?.channelName) {
        let channelName = isMsteams ? `${channel?.groupName} \u2192 ${channel?.channelName}` : channel?.channelName;
        if (!channelName) {
            return selectChannel;
        }
        return `${isMsteams || channel?.isPrivate ? '' : '#'}${channelName}`;
    }
};

export const handleWorkspaceToggleChanges = async ({
    value,
    type,
    config,
    dispatch,
    setHereNotifications,
    setRecognitionsParticularChannel,
    SetSnackbar,
}) => {
    let snackbarMessage, apiData;
    const [broadcastMessages, defaultRecognitionChannel] = ['broadcastMessages', 'defaultRecognitionChannel'];

    switch (type) {
        case broadcastMessages:
            snackbarMessage = `@here notifications ${value ? 'enabled' : 'disabled'} successfully`;
            setHereNotifications(value);
            apiData = {
                broadcastMessages: value,
            };
            break;
        case defaultRecognitionChannel:
            setRecognitionsParticularChannel({ enabled: value });
            if (!value) {
                snackbarMessage = 'Default Recognition channel disabled successfully';
                apiData = {
                    defaultRecognitionChannel: {
                        enabled: value,
                    },
                };
            }
            break;
        default:
            break;
    }

    // for updating @here or whenever toggle value is false
    if (apiData) {
        try {
            await patchData('recognition/configure/workspace', apiData);
            if (type === broadcastMessages) {
                updateWorkspaceDetails(dispatch, { config: { ...config, ...apiData } });
            } else {
                updateWorkspaceDetails(dispatch, { config: { ...config, [type]: { ...apiData[type] } } });
            }
            showSnackBarMessage(SetSnackbar, 'success', snackbarMessage);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    }
};

export const saveWorkspaceDetails = ({ dispatch, data, config }) => {
    updateWorkspaceDetails(dispatch, {
        approver: {
            approverName: data.orgWideApprover.name,
            approverImageURL: data.orgWideApprover.pictureURL,
            approverID: data.orgWideApprover._id,
        },
        config: {
            ...config,
            defaultRecognitionChannel: {
                enabled: data?.defaultRecognitionChannel?.enabled,
                channelName: data?.defaultRecognitionChannel?.channel?.name,
                channelId: data?.defaultRecognitionChannel?.channel?.id,
                groupId: data?.defaultRecognitionChannel?.channel?.groupId,
                groupName: data?.defaultRecognitionChannel?.channel?.groupName,
                isPrivate: data?.defaultRecognitionChannel?.channel?.isPrivate,
            },
            broadcastMessages: data.broadcastMessages,
        },
    });
};

/*------------------- Below are the functions/handlers used for "EnableRewards" component ------------------------*/

export const handleEditRewards = ({
    index,
    value,
    getTableData,
    conversions = [],
    setTableData,
    keyName = 'status',
    originalValue,
    originalStatus,
}) => {
    if (conversions.length) {
        const tempData = [...conversions];
        let tempValue = value;
        if (keyName === 'value') {
            tempValue = !value ? '' : Math.abs(value);
        }
        tempData[index][`${keyName}`] = tempValue;
        tempData[index].modified = keyName === 'value' ? tempValue !== originalValue : tempValue !== originalStatus;
        getTableData({ conversionModalData: tempData, setTableData });
    }
};

export const handleEditRedemption =
    ({ enableRewards, setEnableRewards, dispatch, queryClient, SetSnackbar, handleSave }) =>
    async () => {
        try {
            const apiData = { redemption: !enableRewards };
            setEnableRewards(!enableRewards);
            await handleSave({ apiData });
            getUserProfile(dispatch);
        } catch (error) {
            queryClient.setQueryData([GET_CONFIG_POINTS], (oldData) => {
                const tempData = { ...oldData };
                return tempData;
            });
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

export const findAlterData = (alterConversion) => {
    const apiData = {};
    const alteredData = alterConversion?.filter((item) => item.modified);
    if (alteredData?.length > 0) {
        apiData.conversions = alteredData.map((item) => ({
            ...item,
            conversionId: item._id,
            value: item.value,
            redemptionEnabled: item.redemptionEnabled,
        }));
        return apiData;
    }
    return null;
};

export const hasValidData = (data) => {
    const hasZero = find(data, (item) => item.redemptionEnabled && Number(item?.value) === 0);
    const hasEmpty = find(data, (item) => item?.value === '');
    if (hasZero || hasEmpty) {
        return { status: false, message: `Value cannot be ${hasEmpty ? 'empty' : '0'}` };
    }
    return { status: true, message: '' };
};

/*------------------- Below are the functions/handlers used for "PointsName" component ------------------------*/
export const getCharsLeft = (limit, value = 0) => Math.max(limit - value, 0);

export const pointsNameOnChangeHandler = ({
    value,
    customPoints,
    setCustomPoints,
    customPointsFieldValidation,
    setCustomPointsFieldValidation,
}) => {
    // adding extra condition so that if user attempts to reduce over-sized name (ex through backspace) then that should be allowed)
    if (value.length < 11 || value.length < customPoints.length) {
        setCustomPoints(value.trim());
    }
    setCustomPointsFieldValidation({
        ...customPointsFieldValidation,
        charsLeft: getCharsLeft(10, value.length),
    });
};

export const pointsNameOnFocusedBlurHandler =
    ({ isFocused, setCustomPointsFieldValidation, customPoints }) =>
    () => {
        setCustomPointsFieldValidation({
            isFocused,
            charsLeft: getCharsLeft(10, customPoints.length),
        });
    };
