import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import { ReactComponent as HourGlass } from 'Assets/images/hourglass.svg';
import { ReactComponent as AcceptOrReject } from 'Assets/images/approveOrReject.svg';
import ViewApproveOrRejectModal from '../ViewApproveOrRejectModal';
import AvatarGroupPopup from 'components/ReusableComponents/AvatarGroupPopup';
import { getFormattedDate } from 'utils/HelperFunctions';
import MemberTag from '../components/MemberTag';
import { HISTORY_FROM } from 'constants.js';
import { useLocation } from 'react-router-dom';
import { event_mapper } from 'components/Dashboard/Content/Home/ActionPanel/TasksWidget/constants';
import './style.scss';

const Index = ({ taskItemData, history, nameForPoints }) => {
    const [viewApproveOrRejectPanel, setViewApproveOrRejectPanel] = useState(false);
    const isSelfRecognition = taskItemData.users?.type === 'recognition_pending_approval';
    const location = useLocation();
    const originalDate = new Date(taskItemData?.timestamp);
    const formattedDate = getFormattedDate(originalDate);

    useEffect(() => {
        if (
            location.state?.taskId &&
            location.state?.taskId === taskItemData._id &&
            event_mapper[location.state?.event] === taskItemData.users?.type
        ) {
            setViewApproveOrRejectPanel(true);
        }
        return () => {
            setViewApproveOrRejectPanel(false);
        };
    }, [location.state?.taskId, taskItemData._id]);

    return (
        <div className='recognitions-tasks-container'>
            <div className='recognition-container'>
                <div className='accept-reject-image'>{isSelfRecognition ? <HourGlass /> : <AcceptOrReject />}</div>
                <div className='recognition-data'>
                    <p className='timestamp'>{formattedDate}</p>
                    <div className='recognition-text'>
                        {isSelfRecognition ? (
                            <p>
                                Your{' '}
                                <b>
                                    {taskItemData?.metadata?.rewardType} ({taskItemData?.metadata?.points}{' '}
                                    {nameForPoints})
                                </b>{' '}
                                to &nbsp;
                            </p>
                        ) : (
                            <div className='d-flex'>
                                <MemberTag
                                    history={history}
                                    memberID={taskItemData?.metadata?.sender?._id}
                                    memberName={taskItemData?.metadata?.sender?.userName}
                                    memberPhoto={taskItemData?.metadata?.sender?.pictureURL}
                                />
                                <p>
                                    &nbsp;wants to give{' '}
                                    <b>
                                        {' '}
                                        {taskItemData?.metadata?.rewardType} ({taskItemData?.metadata?.points}{' '}
                                        {nameForPoints})
                                    </b>{' '}
                                    &nbsp;to &nbsp;
                                </p>
                            </div>
                        )}

                        <div className='user-btn-container'>
                            <MemberTag
                                history={history}
                                memberID={taskItemData?.metadata?.receivers[0]?._id}
                                memberName={taskItemData?.metadata?.receivers[0]?.userName}
                                memberPhoto={taskItemData?.metadata?.receivers[0]?.pictureURL}
                            />
                            {taskItemData?.metadata?.receivers.length > 1 && (
                                <span style={{ marginLeft: '5px' }}>&&nbsp;</span>
                            )}
                            {taskItemData?.metadata?.receivers.length === 2 && (
                                <MemberTag
                                    history={history}
                                    memberID={taskItemData?.metadata?.receivers[1]?._id}
                                    memberName={taskItemData?.metadata?.receivers[1]?.userName}
                                    memberPhoto={taskItemData?.metadata?.receivers[1]?.pictureURL}
                                />
                            )}
                            {taskItemData?.metadata?.receivers.length > 2 && (
                                <AvatarGroupPopup
                                    assignedMembers={taskItemData?.metadata?.receivers.slice(1)}
                                    moreUsers={true}
                                    customHeight={'0px'}
                                    marginLeft={'-14px'}
                                    history={history}
                                    fromLocation={HISTORY_FROM.TASKS}
                                />
                            )}
                        </div>
                        {isSelfRecognition && <p>&nbsp;is pending.</p>}
                    </div>
                    {taskItemData?.metadata?.message && (
                        <p className='recognition-message'>{`"${taskItemData?.metadata?.message}"`}</p>
                    )}
                </div>
            </div>
            <div className='manage-button'>
                <EWButton buttonText={'Manage'} onClick={() => setViewApproveOrRejectPanel(true)} />
            </div>
            {viewApproveOrRejectPanel && (
                <ViewApproveOrRejectModal
                    open={viewApproveOrRejectPanel}
                    setOpen={setViewApproveOrRejectPanel}
                    recognition={taskItemData?.metadata}
                    timestamp={taskItemData?.timestamp}
                    isSelfRecognition={isSelfRecognition}
                    users={taskItemData?.metadata?.receivers}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    taskItemData: PropTypes.object,
    history: PropTypes.object,
    nameForPoints: PropTypes.string,
};

export default Index;
