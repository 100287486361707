import React from 'react';
import { ReactComponent as EmptyFeedImage } from 'Assets/images/no-feedback.svg';
import './style.scss';

const Index = () => {
    return (
        <div className='container-empty-recognition-feed'>
            <div className='cer-feed-image'>
                <EmptyFeedImage />
            </div>
            <p className='cer-feed-title'>Oops! It looks like there are no results found!</p>
            <p className='cer-feed-sub-title'>
                Try adjusting the filters or celebrate a colleague's outstanding contributions!
            </p>
        </div>
    );
};

export default Index;
