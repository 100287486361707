import { getData } from 'Services/apiCall';

export const getPendingTasks = async ({ queryKey: [_key] }) => {
    try {
        const path = 'users/tasks?feed=true';
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
