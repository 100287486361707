import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as OpenChannelIcon } from 'Assets/images/icon-open-channel.svg';
import { ReactComponent as AddOnIcon } from 'Assets/images/ic_round-plus.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import TextWithViewMore from 'components/ReusableComponents/TextWithViewMore';
import { Tooltip } from 'components';
import { useSelector, shallowEqual } from 'react-redux';
import { makePlural } from 'utils/HelperFunctions';
import './style.scss';
import { PLATFORM } from 'constants.js';

const RecognitionMessage = ({ children }) => {
    return <div className='container-recognition-message'>{children}</div>;
};

RecognitionMessage.propTypes = {
    children: PropTypes.node.isRequired,
};

const Title = ({ sender, channel, messageLink, showChannelIcon = false }) => {
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const isWebOnlyUser = platform === PLATFORM.WEB;

    return (
        <div className='recognition-message-title'>
            <p>
                Here's what <strong>{sender.userName}</strong> has to say:
            </p>
            {!isWebOnlyUser && (
                <EWButton
                    plainTextButton
                    primary={false}
                    buttonStyleClass='recognition-message-channel'
                    height='24px'
                    onClick={() => window.open(messageLink, '_blank')}
                >
                    <span className='recognition-message-channel-content'>
                        {!showChannelIcon && (
                            <>
                                Sent on&nbsp;<strong>{channel.channelName}</strong>&nbsp;
                            </>
                        )}
                        <Tooltip title={showChannelIcon ? `Sent on ${channel.channelName}` : ''}>
                            <OpenChannelIcon />
                        </Tooltip>
                    </span>
                </EWButton>
            )}
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
});

Title.propTypes = {
    sender: PropTypes.shape({
        userName: PropTypes.string.isRequired,
    }).isRequired,
    channel: PropTypes.shape({
        channelName: PropTypes.string.isRequired,
    }).isRequired,
    messageLink: PropTypes.string.isRequired,
    showChannelIcon: PropTypes.bool,
};

const Content = ({ message, companyValues, showViewMoreButtonForMessage = false, showAddOnValue = false }) => {
    if (!message && !showAddOnValue) return null;

    return (
        <div className='recognition-message'>
            <div className='recognition-message-divider' />
            <div className='d-flex flex-column'>
                {message &&
                    (showViewMoreButtonForMessage ? (
                        <TextWithViewMore text={message} maxLines={2} />
                    ) : (
                        <p className='recognition-message-content'>{message}</p>
                    ))}
                {showAddOnValue && companyValues?.length > 0 && (
                    <p className='recognized-users-value'>
                        {`${makePlural(companyValues.length, 'Value', true)}`} highlighted:{' '}
                        <strong>{companyValues.join(', ')}</strong>
                    </p>
                )}
            </div>
        </div>
    );
};

Content.propTypes = {
    message: PropTypes.string,
    companyValues: PropTypes.arrayOf(PropTypes.string),
    showViewMoreButtonForMessage: PropTypes.bool,
    showAddOnValue: PropTypes.bool,
};

const Image = ({ gifLink }) => {
    return gifLink ? <img className='recognition-image' src={gifLink} alt='recognition-message' /> : null;
};

Image.propTypes = {
    gifLink: PropTypes.string,
};

const AddOnButton = ({ handleGiveAddOnRecognition, recognitionData }) => {
    return (
        <EWButton
            plainTextButton
            border
            primary={false}
            height='36px'
            width='181px'
            buttonStyleClass='recognition-btn-add-on'
            onClick={() => handleGiveAddOnRecognition(recognitionData)}
        >
            <span>
                <AddOnIcon />
                &nbsp; Add on recognition
            </span>
        </EWButton>
    );
};

AddOnButton.propTypes = {
    handleGiveAddOnRecognition: PropTypes.func.isRequired,
    recognitionData: PropTypes.object.isRequired,
};

const Divider = ({ addOnRecognitionsCount }) => {
    return addOnRecognitionsCount > 0 ? <div className='recognition-divider' /> : null;
};

Divider.propTypes = {
    addOnRecognitionsCount: PropTypes.number.isRequired,
};

RecognitionMessage.Title = Title;
RecognitionMessage.Content = Content;
RecognitionMessage.Image = Image;
RecognitionMessage.AddOnButton = AddOnButton;
RecognitionMessage.Divider = Divider;

export default RecognitionMessage;
