import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BonusHome from './BonusHome';
import LaunchBonus from './LaunchBonus';
import { BONUS_ROUTE } from './constants';

const Index = () => {
    return (
        <div>
            <Switch>
                <Route exact path={BONUS_ROUTE.HOME} component={BonusHome} />
                <Route exact path={BONUS_ROUTE.LAUNCH} component={LaunchBonus} />
            </Switch>
        </div>
    );
};

export default Index;
