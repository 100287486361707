import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RequestedUsers from '../../RequestedUsers';
import RequestedChannels from '../../RequestedChannels';
import './style.scss';
import Tooltip from 'components/Styles/Tooltip';
import { format, addDays } from 'date-fns';
import { PULSE_FREQUENCY } from 'constants.js';
import { capitalizeFirstLetter } from 'utils/HelperFunctions';
import { PULSE_STATUS, PULSE_SUB_TYPE, PULSE_TYPE } from '../../constants';
import { ReactComponent as InfoIcon } from 'Assets/images/info-white-filled.svg';

const Index = ({ details, responseCount, enps, pulseResponse }) => {
    const [moreUsers, setMoreUsers] = useState(false);
    const [moreChannels, setMoreChannels] = useState(false);

    let launchedOn = '-';
    let nextLaunchStart = '-';
    let nextLaunchEnd = '-';
    if (details?.type === PULSE_TYPE.RECURRING) {
        nextLaunchStart = format(new Date(details?.nextLaunchAt), 'do MMMM yyyy');
        nextLaunchEnd = format(addDays(new Date(details?.nextLaunchAt), details?.allowResponsesFor), 'do MMMM yyyy');
    }
    const endedOn = format(new Date(details?.endAt), 'do MMMM yyyy');
    if (details?.startAt) {
        launchedOn = format(new Date(details.startAt), 'do MMMM yyyy');
    } else if (details?.createdAt) {
        launchedOn = format(new Date(details.createdAt), 'do MMMM yyyy');
    }

    const pulseStatus = () => {
        const globalStatus = enps ? details?.pulseStatus?.toLowerCase() : details?.status?.toLowerCase();
        if (details?.type !== PULSE_TYPE.RECURRING) {
            return globalStatus === PULSE_STATUS.ACTIVE ? PULSE_STATUS.ONGOING : PULSE_STATUS.COMPLETED;
        }
        return details?.isRecurringStopped && globalStatus === PULSE_STATUS.COMPLETED
            ? PULSE_STATUS.INACTIVE
            : PULSE_STATUS.ACTIVE;
    };

    const recurrence = () => {
        if (details?.recurrenceType === capitalizeFirstLetter(PULSE_FREQUENCY.WEEKLY)) {
            return `${format(new Date(details?.startAt), 'EEEE')}s`;
        }
        return `${format(new Date(details?.startAt), 'do')}`;
    };

    return (
        <div
            className='pulse-details-container'
            style={{ height: enps && details.type === 'one-time' ? '70px' : '104px' }}
        >
            <div className='d-flex justify-content-between'>
                <Tooltip title={details?.name} placement='top'>
                    <h4 className='mb-0'>
                        {details?.name?.length > 200 ? details.name.slice(0, 197) + '...' : details?.name}{' '}
                        <span className='ml-2 capitalize'>{pulseStatus()}</span>
                    </h4>
                </Tooltip>
                {
                    <div className='d-flex justify-content-start createdBy'>
                        <img src={details?.createdBy?.pictureURL} alt='user' />
                        <p className='ml-2 mb-0'>Hosted by {details?.createdBy?.userName}</p>
                    </div>
                }
            </div>
            {((details.type !== 'one-time' && enps) || !enps) && (
                <p className={`count-respondants mt-3`}>
                    {details?.type === PULSE_TYPE.RECURRING && (
                        <span>
                            Type: <b>Recurring pulse</b> &nbsp;&nbsp;&bull;&nbsp;&nbsp;{' '}
                            {!details?.isRecurringStopped && (
                                <span>
                                    Recurring Frequency:{' '}
                                    <b>
                                        {details?.recurrenceType} on {recurrence()}
                                    </b>{' '}
                                    &nbsp; &nbsp;&nbsp;&bull;&nbsp;&nbsp; Next instance: {nextLaunchStart} -{' '}
                                    {nextLaunchEnd}
                                </span>
                            )}
                            {details?.isRecurringStopped && (
                                <span>
                                    Next instance: <b>Stopped</b>
                                </span>
                            )}
                        </span>
                    )}
                    {!enps && details?.type !== PULSE_TYPE.RECURRING && (
                        <span>
                            No. of questions: {details?.questions?.length} &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                            {details?.subtype === PULSE_SUB_TYPE.ROLLING && (
                                <span>
                                    Type: <b>Rolling pulse</b> &nbsp;&nbsp;&bull;&nbsp;&nbsp;{' '}
                                </span>
                            )}
                            Total Respondents: {responseCount}/{details?.requestedUsers?.length} &nbsp;
                            <Tooltip
                                arrow
                                title={`${pulseResponse?.completeResponseCount || 0} completely responded and ${
                                    pulseResponse?.partialResponseCount || 0
                                } partially responded`}
                            >
                                <InfoIcon className='info-icon' />
                            </Tooltip>
                            &nbsp;&nbsp;&bull;&nbsp;&nbsp; Duration: {launchedOn} - {endedOn}
                        </span>
                    )}
                </p>
            )}
            <RequestedUsers open={moreUsers} setOpen={setMoreUsers} users={details?.requestedUsers} />
            <RequestedChannels open={moreChannels} setOpen={setMoreChannels} channels={details?.channels} />
        </div>
    );
};

Index.propTypes = {
    details: PropTypes.object,
    responseCount: PropTypes.number,
    pulseResponse: PropTypes.object,
    enps: PropTypes.object,
};

export default Index;
