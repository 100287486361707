import React from 'react';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import Carousel from './carousel';
import { getSlides } from './events';
import { ROUTES } from 'constants.js';
import './styles.scss';

const Index = ({ history, values }) => {
    const slides = getSlides(values, history);
    return (
        <div className='tasks-widget-container'>
            <div className='top-container'>
                <Carousel tasks={slides} />
            </div>
            <div className='bottom-container'>
                <EWButton
                    plainTextButton={true}
                    primary={false}
                    buttonStyleClass='view-all-tasks-btn'
                    onClick={() => history.push(ROUTES.TASKS)}
                >
                    View all tasks
                </EWButton>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    values: PropTypes.arrayOf(PropTypes.object),
};

export default Index;
