import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { ReactComponent as Warning } from 'Assets/images/warning-red.svg';

const Index = ({ errorText, className = '', warningIcon = true, lightErrorText = false }) => {
    return (
        <div className={`error-field ${className}`}>
            <div className='d-flex align-items-center no-warning-icon'>{warningIcon && <Warning />}</div>
            <div className={lightErrorText ? 'light-error-text' : 'error-text'}>{errorText}</div>
        </div>
    );
};

Index.propTypes = {
    errorText: PropTypes.string,
    className: PropTypes.string,
    warningIcon: PropTypes.bool,
    lightErrorText: PropTypes.bool,
};

export default Index;
