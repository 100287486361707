import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { deleteData } from 'Services/apiCall';
import { useToaster } from 'Context/SnackbarContext';
import { GET_CORE_VALUES } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const deleteValue = async (id) => {
    try {
        await deleteData(`recognition/configure/company-values/${id}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

const RemoveValuesModal = ({ values, deleteIndex, setDeleteModal, updateIsValuesRequiredState }) => {
    const { mutateAsync, isLoading } = useMutation(deleteValue);
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();

    const handleClose = () => {
        setDeleteModal({ open: false, index: -1 });
    };

    const removeValue = async () => {
        try {
            await mutateAsync(values[deleteIndex].id);
            queryClient.setQueryData([GET_CORE_VALUES], (oldData) => {
                const tempData = { ...oldData };
                tempData.values.splice(deleteIndex, 1);
                return tempData;
            });
            showSnackBarMessage(SetSnackbar, 'success', 'Value deleted successfully');
            handleClose();
            if (values.length < 1) {
                updateIsValuesRequiredState(false, true);
            }
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const text = {
        heading: 'Deleting Value',
        subHeading: 'Are you sure you want to go ahead with deleting the value?',
        confirm: 'Delete',
    };

    return (
        <ConfirmModal open={true} onClose={handleClose} onConfirm={removeValue} data={text} loading={isLoading} red />
    );
};

RemoveValuesModal.propTypes = {
    values: PropTypes.array,
    deleteIndex: PropTypes.number,
    setDeleteModal: PropTypes.func,
    updateIsValuesRequiredState: PropTypes.func.isRequired,
};

export default RemoveValuesModal;
