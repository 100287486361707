import React from 'react';
import RecognizedUsers from 'components/Dashboard/Content/Recognitions/RecognitionsHome/RecognitionFeed/RecognitionItem/RecognizedUsers';
import RecognitionMessage from 'components/Dashboard/Content/Recognitions/RecognitionsHome/RecognitionFeed/RecognitionItem/RecognitionMessage';
import EWButton from 'components/ReusableComponents/EWButton';
import ImageStack from 'components/ReusableComponents/ImageStack';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';

const Index = ({ recognitionData, handleGiveAddOnRecognition, setViewAddOnRecognitionsModal }) => {
    // if same user gives multiple add on recognitions then show user's name and pic only once
    const uniqueAddOnRecognitions = uniqBy(recognitionData.recognition.addOnRecognitions, 'sender._id');
    const addOnRecognitionSenders = uniqueAddOnRecognitions.map((addOnRecognition) => addOnRecognition.sender);

    const getAddOnRecognitionSenderNames = () => {
        const userNames = addOnRecognitionSenders.map((addOnRecognitionSender) => addOnRecognitionSender.userName);
        let names = '';
        if (userNames.length === 1) {
            names = userNames[0];
        } else if (userNames.length === 2) {
            names = userNames.join(' and ');
        } else if (userNames.length > 2) {
            names = `${userNames.slice(0, 2).join(',')}  and ${userNames.length - 2} more`;
        }
        return `${names} gave Add on ${userNames.length === 1 ? 'recognition' : 'recognitions'} `;
    };

    return (
        <>
            <RecognizedUsers users={recognitionData?.receivers} value={recognitionData?.recognition.companyValues} />
            <RecognitionMessage>
                <RecognitionMessage.Title
                    sender={recognitionData.sender}
                    channel={recognitionData.channel}
                    messageLink={recognitionData.recognition.messageLink}
                />
                <RecognitionMessage.Content message={recognitionData.recognition.message} showAddOnValue />
                <RecognitionMessage.Image gifLink={recognitionData.recognition.gifLink} />
                <RecognitionMessage.AddOnButton
                    handleGiveAddOnRecognition={handleGiveAddOnRecognition}
                    recognitionData={recognitionData}
                />
                <RecognitionMessage.Divider
                    addOnRecognitionsCount={recognitionData.recognition.addOnRecognitions.length}
                />
            </RecognitionMessage>
            {uniqueAddOnRecognitions.length > 0 && (
                <EWButton plainTextButton primary={false} onClick={() => setViewAddOnRecognitionsModal(true)}>
                    <div className='recognition-message-add-ons'>
                        <ImageStack members={addOnRecognitionSenders} width={21} height={21} />
                        <p>
                            {`${getAddOnRecognitionSenderNames()}`} <ArrowRightIcon />
                        </p>
                    </div>
                </EWButton>
            )}
        </>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
    handleGiveAddOnRecognition: PropTypes.func,
    setViewAddOnRecognitionsModal: PropTypes.func,
};

export default Index;
