import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './style.scss';

const Index = ({
    images,
    selectedImage,
    setSelectedImage,
    buttonStyleClass,
    disabled,
    dropdownGap,
    dropdownHeight,
    dropdownStyleClass,
    dropdownWidth,
}) => {
    const [open, setOpen] = useState(false);

    const handleImageSelection = (image) => {
        if (image === selectedImage) {
            setSelectedImage('');
        } else {
            setSelectedImage(image);
        }
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className='container-image-select'>
                <button
                    className={clsx({
                        'image-dropdown-select-button': true,
                        'image-dropdown-button-open': open,
                        [buttonStyleClass]: buttonStyleClass,
                    })}
                    onClick={() => setOpen(!open)}
                    disabled={disabled}
                >
                    <span className={clsx({ 'label-highlighted': open })}>GIF</span>
                </button>
                {images.length > 0 && (
                    <div
                        className={clsx({
                            'image-dropdown-container': true,
                            'display-none': !open,
                            [dropdownStyleClass]: dropdownStyleClass,
                        })}
                        style={{
                            height: dropdownHeight || (images?.length <= 3 ? 'auto' : 140),
                            width: dropdownWidth,
                            top: `${48 + dropdownGap}px`,
                        }}
                    >
                        <div className='image-grid'>
                            {images.map((image) => (
                                <img
                                    key={image}
                                    src={image}
                                    alt='GIF option'
                                    className={clsx('image-grid-item', {
                                        selected: selectedImage === image,
                                    })}
                                    onClick={() => handleImageSelection(image)}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

Index.propTypes = {
    images: PropTypes.array.isRequired,
    selectedImage: PropTypes.string.isRequired,
    setSelectedImage: PropTypes.func.isRequired,
    buttonStyleClass: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownGap: PropTypes.number,
    dropdownHeight: PropTypes.string,
    dropdownStyleClass: PropTypes.string,
    dropdownWidth: PropTypes.string,
};

Index.defaultProps = {
    buttonStyleClass: '',
    disabled: false,
    dropdownGap: 0,
    dropdownHeight: '',
    dropdownStyleClass: '',
    dropdownWidth: '',
};

export default Index;
