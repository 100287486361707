import MemberTag from 'components/Dashboard/Content/Activities/components/MemberTag';
import { KudosTag, ShoutoutTag, GreenPointsTag } from 'utils/Stylesheet/style';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { TRANSACTION_STATUS } from 'components/Dashboard/Content/Activities/components/StatusChips/constants';
import { STATUS } from 'components/Dashboard/Content/Activities/constants';
import { STATUS_STATES } from './constants';
import { PAGESTATE } from './../../Dashboard/Content/Activities/constants';
import ConditionalTooltipWrapper from 'components/ReusableComponents/ToolTipWrapper';

const InfoField = ({ description }) => {
    return (
        <ConditionalTooltipWrapper condition={description?.length > 98} title={description}>
            <p className='description'>{description?.length > 98 ? description?.slice(0, 95) + '...' : description}</p>
        </ConditionalTooltipWrapper>
    );
};

const Index = ({ open, onClose, rewardInfo, transactionState, from }) => {
    if (!open) {
        return null;
    }

    const rewardStatus = rewardInfo.status;

    return (
        <Modal open={open}>
            <div className='reward-modal-container '>
                <div className='modal-action'>
                    <CloseIcon className='pointer-cursor' onClick={onClose} />
                </div>
                <div className='reward-details'>
                    <div
                        className='reward-details-header'
                        style={{
                            backgroundColor: rewardStatus
                                ? TRANSACTION_STATUS[rewardStatus]['background']
                                : '#80808014',
                        }}
                    >
                        <div className='reward-details-header-title'>
                            {transactionState === STATUS.GIVEN || transactionState === STATUS.REQUESTED ? (
                                <p>{rewardStatus}</p>
                            ) : (
                                <p>{transactionState === STATUS.APPROVED ? 'Completed' : transactionState}</p>
                            )}
                            {transactionState !== 'pending' && <p> {rewardInfo.updatedOn || rewardInfo?.sentOn}</p>}
                        </div>
                        <div className='reward-details-header-points'>
                            {rewardInfo?.approver && (
                                <p>
                                    {from !== PAGESTATE.MYACTIVITIES ? (
                                        <>
                                            {transactionState === STATUS_STATES.APPROVED && (
                                                <strong>Approved by </strong>
                                            )}
                                            {(transactionState === STATUS_STATES.PENDING ||
                                                (rewardStatus === STATUS_STATES.EXPIRED &&
                                                    transactionState === STATUS_STATES.REJECTED)) && (
                                                <strong>Approver </strong>
                                            )}
                                            {(transactionState === STATUS_STATES.REJECTED ||
                                                rewardStatus === STATUS_STATES.REJECTED_STATUS) &&
                                                rewardStatus !== STATUS_STATES.EXPIRED && <strong>Rejected By </strong>}
                                        </>
                                    ) : (
                                        <>
                                            {rewardStatus === STATUS_STATES.SENT && <strong>Approved by </strong>}
                                            {(rewardStatus === STATUS_STATES.APPROVAL_PENDING ||
                                                rewardStatus === STATUS_STATES.CANCELLED ||
                                                rewardStatus === STATUS_STATES.EXPIRED) && <strong>Approver </strong>}
                                            {rewardStatus === STATUS_STATES.REJECTED_STATUS &&
                                                rewardStatus !== STATUS_STATES.EXPIRED && <strong>Rejected By </strong>}
                                        </>
                                    )}

                                    <MemberTag
                                        memberPhoto={rewardInfo?.approver?.pictureURL}
                                        memberName={rewardInfo?.approver?.name}
                                        memberID={rewardInfo?.approver?.id}
                                    />
                                </p>
                            )}
                            {rewardInfo?.rejectionReason && (
                                <p>
                                    <strong>Reason </strong>
                                    {rewardInfo?.rejectionReason}
                                </p>
                            )}
                            <div className=' reward-tag-container'>
                                <div className='reward-tag'>
                                    {rewardInfo?.recognitionType === 'shoutout' ? (
                                        <ShoutoutTag>{rewardInfo?.recognitionType}</ShoutoutTag>
                                    ) : (
                                        <KudosTag>{rewardInfo?.recognitionType}</KudosTag>
                                    )}
                                </div>
                                <GreenPointsTag>{rewardInfo?.points}</GreenPointsTag>
                            </div>
                        </div>
                    </div>

                    <div className='reward-details-body'>
                        <div className='reward-info'>
                            <div className='label'>From</div>
                            <MemberTag
                                memberPhoto={rewardInfo?.sender?.pictureURL}
                                memberName={rewardInfo?.sender?.name}
                            />
                        </div>

                        <div className='reward-info'>
                            <div className='label'>To</div>
                            <MemberTag
                                memberPhoto={rewardInfo?.receiver?.pictureURL}
                                memberName={rewardInfo?.receiver?.name}
                            />
                        </div>
                        <div className='reward-info'>
                            <div className='label'>Sent on</div>
                            <p className='reward-date'>{rewardInfo?.sentOn}</p>
                        </div>
                        <div className='reward-value'>
                            <span className='label'>Value</span>
                            <InfoField description={rewardInfo?.values?.join(', ') || '-'} />
                        </div>
                        <div className='reward-des'>
                            <span className='label'>Message</span>
                            <InfoField description={rewardInfo?.message} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    rewardInfo: PropTypes.object,
    transactionState: PropTypes.string,
    from: PropTypes.string,
};

InfoField.propTypes = {
    description: PropTypes.string,
};

export default Index;
