import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import Banner from 'components/ReusableComponents/CustomBanner';
import { ReactComponent as BonusBanner } from 'Assets/images/bonus-banner.svg';
import BonusTables from './BonusTables';
import { BONUS_ROUTE } from './constants';
import { toggleFullscreen } from 'redux/layout/actions';
import { useDispatch } from 'react-redux';

import './style.scss';

const BonusHome = ({ history }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        toggleFullscreen(dispatch, false);
    }, []);

    const bannerDetail = {
        history,
        title: (
            <span>
                Appreciate your superheroes for acing their <br /> work like a pro.
            </span>
        ),
        buttonLabel: 'Award Bonus',
        targetRoute: BONUS_ROUTE.LAUNCH,
        Icon: BonusBanner,
        currentRoute: BONUS_ROUTE.HOME,
        backgroundColor: '#407474',
    };

    return (
        <div className='container-employee-home'>
            <TitleBar title='Bonus' />
            <Banner {...bannerDetail} />
            <BonusTables history={history} />
        </div>
    );
};

BonusHome.propTypes = {
    history: PropTypes.object,
};

export default BonusHome;
