import React from 'react';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import MemberTag from 'components/ReusableComponents/MemberTag';
import { ROUTES } from 'constants.js';
import '../styles.scss';

const Index = ({ data, history }) => {
    return (
        <div className=' events-container'>
            <div className='content'>
                <p className='event-description'>
                    <MemberTag
                        memberId={data.sender._id}
                        memberName={data.sender.userName}
                        memberPhoto={data.sender.pictureURL}
                    />{' '}
                    wants to give{' '}
                    <span className='award-name-text'>
                        {data.recognitionName} ({data.points} pts)
                    </span>{' '}
                    to:{' '}
                    <MemberTag
                        memberId={data.receivers[0]._id}
                        memberName={data.receivers[0].userName}
                        memberPhoto={data.receivers[0].pictureURL}
                    />
                    {data.additionalReceivers > 0 && ` & ${data.additionalReceivers} more.`}
                </p>
            </div>
            <div className='footer'>
                <EWButton
                    buttonStyleClass='events-action-btn'
                    onClick={() => history.push(ROUTES.TASKS, { taskId: data._id, event: data.event })}
                >
                    Manage
                </EWButton>
            </div>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
};

export default Index;
