import { format, parseISO } from 'date-fns';
import React from 'react';
import { ReactComponent as CertificateIcon } from 'Assets/images/file-certificate.svg';
import EWButton from 'components/ReusableComponents/EWButton';

export const rowFormatter = (award, setShowCertificate, setCertificateInfo, userName) => ({
    id: award._id,
    row: [
        <strong key={award._id}>{award.awardName}</strong>,
        award?.awardDate ? format(parseISO(award.awardDate), 'MMM dd, yyyy') : '-',
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
        <div key={award._id} className='d-flex align-items-center width-100'>
            <EWButton
                plainTextButton={true}
                primary={false}
                className='d-flex align-items-center'
                onClick={() => {
                    setShowCertificate(true);
                    setCertificateInfo({
                        awardee: userName,
                        awardName: award.awardName,
                        awardDate: format(parseISO(award.awardDate), 'MMM dd, yyyy'),
                        awardIcon: award.icon,
                    });
                }}
            >
                <CertificateIcon />
                <span className='ml-2'>View Certificate</span>
            </EWButton>
        </div>,
    ],
});

export const getSortHandler = (sort, setSort, tableProperties) => (index) =>
    setSort({ key: tableProperties.SORT_KEY[index], direction: -(sort.direction || -1) });
