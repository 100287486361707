import React from 'react';
import RecognitionForm from './RecognitionForm';
import ActionPanel from './ActionPanel';
import RecognitionHome from 'components/Dashboard/Content/Recognitions/RecognitionsHome';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ history }) => {
    return (
        <div className='container-home'>
            <div className='d-flex align-items-center flex-column mr-3'>
                <RecognitionForm />
                <RecognitionHome history={history} />
            </div>
            <ActionPanel history={history} />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
