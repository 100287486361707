import React from 'react';
import { ROUTES, PLATFORM } from 'constants.js';
import { conditionalArrayElem } from 'utils/HelperFunctions';
import { ReactComponent as PulseIcon } from 'Assets/images/noun-discussion.svg';
import { ReactComponent as FeedbackIcon } from 'Assets/images/feedback-icon.svg';
import { ReactComponent as EmployeeAwardsIcon } from 'Assets/images/employeeAwardSidenavIcon.svg';
import { ReactComponent as SuggestionBoxIcon } from 'Assets/images/suggestion-box-icon-otheractions.svg';
import { ReactComponent as BonusIcon } from 'Assets/images/bonus.svg';

export const getQuickActions = (platform) => {
    const isWebOnly = platform === PLATFORM.WEB.toLowerCase();
    const isSlackPlatform = platform === PLATFORM.SLACK.toLowerCase();

    return [
        ...conditionalArrayElem(isSlackPlatform, [
            {
                label: 'Send/Request Feedback',
                route: ROUTES.FEEDBACK_HOME,
                Icon: FeedbackIcon,
            },
            {
                label: 'Suggestion Box',
                route: ROUTES.SUGGESTION_BOX_HOME,
                Icon: SuggestionBoxIcon,
            },
        ]),
        ...conditionalArrayElem(!isWebOnly, [
            {
                label: 'Launch Award',
                route: ROUTES.EMPLOYEE_AWARDS_LAUNCH,
                Icon: EmployeeAwardsIcon,
            },
            {
                label: 'Launch Pulse',
                route: ROUTES.PULSE_HOME,
                Icon: PulseIcon,
            },
        ]),
        {
            label: 'Send Bonus',
            route: ROUTES.BONUS,
            Icon: BonusIcon,
        },
    ];
};
