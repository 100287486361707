/**
 * Event Mapper: Translates event names between Feed API and Tasks page representations.
 *
 * Purpose:
 * - Provides a mapping between event names used in the Feed API (keys) and
 *   their corresponding representations on the Tasks page (values).
 * - Enables correct task item selection when navigating from the Feed page to the Tasks page.
 *
 * Usage:
 * - When a user clicks the "Manage" button on the Feed page, this mapper ensures
 *   that the corresponding task item is correctly identified and opened on the Tasks page.
 *
 * Mapping:
 * - Feed API event names -> Tasks page event names
 * - 'approval_pending' -> 'recognition_pending_approval'
 * - 'requesting_approval' -> 'recognition_requires_approval'
 *
 * Note:
 * This translation is necessary because the Feed API and Tasks page use different
 * values to represent the same events. Without this mapper, navigation between
 * these pages would not correctly identify the intended task item.
 */
export const event_mapper = {
    approval_pending: 'recognition_pending_approval',
    requesting_approval: 'recognition_requires_approval',
};
