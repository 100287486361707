import React, { useEffect, useState } from 'react';
import LabelWithTooltip from 'components/ReusableComponents/LabelWithTooltip';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { find } from 'lodash';
import { getCountries } from 'utils/countryUtils';
import PropTypes from 'prop-types';

const BonusPrize = ({ bonusPrize, handleBonusPrize, workspaceCountry, bonusPrizeAmount }) => {
    const countries = getCountries(true);
    const defaultCountry = countries.filter((item) => item.countryCode === workspaceCountry);
    const [selectedCurrency, setSelectedCurrency] = useState(defaultCountry?.[0]);

    const getSelectedCurrency = (country) => (
        <p className='country-selection-filter mb-0'>
            {country?.countryFlag && <img src={country?.countryFlag} alt='' />}
            {country?.currency}
        </p>
    );

    const handlePrizeAmount = (event) => {
        const value = !event.target.value ? '' : Math.abs(event.target.value);
        handleBonusPrize(value);
    };

    useEffect(() => {
        setSelectedCurrency(
            find(countries, (country) => country?.country === bonusPrize.country) || defaultCountry?.[0]
        );
    }, [bonusPrize.country]);

    return (
        <div className='bonus-prize'>
            <LabelWithTooltip
                text='Bonus Prize'
                tooltipText='This is the Bonus prize that will be awarded to the winner in their EngageWith wallet'
            />
            <div className='bonus-input-container'>
                <input
                    className='bonus-input-value'
                    value={bonusPrizeAmount}
                    type='number'
                    onChange={handlePrizeAmount}
                />
                <CustomFilterDropdown
                    dropDownID={'selectCountry'}
                    selectedName={getSelectedCurrency(selectedCurrency)}
                    optionsSelected
                    buttonStyleClass='width-132'
                    dropdownWidth='216px'
                    dropdownHeight='224px'
                    disabled={true}
                />
            </div>
        </div>
    );
};

BonusPrize.propTypes = {
    bonusPrize: PropTypes.object,
    handleBonusPrize: PropTypes.func,
    workspaceCountry: PropTypes.string,
    bonusPrizeAmount: PropTypes.number,
};

export default BonusPrize;
