import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useToaster } from 'Context/SnackbarContext';
import { useQuery } from 'react-query';
import { GET_ANALYTICS_COHORT_DATA } from 'Services/apiKeys';
import { getAnalyticsCohortData } from 'Services/apiFunctions';
import { ANALYTICS_GROUPBY } from 'constants.js';
import Analytics from './Analytics';
import { ANALYTICS } from './constants';
import AnalyticsTables from './AnalyticsTables';
import { getDefaultDateRange, showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

export default function Index({ history }) {
    const location = useLocation();
    const { userId, userName, isAdmin } = useSelector(mapStateToProps, shallowEqual);

    const [selectedCohort, setSelectedCohort] = useState(location?.state?.selectedCohort);
    const [selectedDateRange, setSelectedDateRange] = useState(getDefaultDateRange());
    const [analyticsGroupBy, setAnalyticsGroupBy] = useState(ANALYTICS_GROUPBY.RECOGNITIONS);
    // view for the line chart - weekly, monthly or yearly
    const [view, setView] = useState('weekly');

    const { SetSnackbar } = useToaster();

    const { data: cohortData, isLoading } = useQuery([GET_ANALYTICS_COHORT_DATA], getAnalyticsCohortData, {
        onSuccess: (analyticsCohortData) => setSelectedCohort(analyticsCohortData?.organisation?.[0]),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        enabled: isAdmin && location.pathname === ANALYTICS.ANALYTICS_DASHBOARD,
    });

    const managerCohort = {
        managers: [
            {
                _id: userId,
                name: userName,
                type: 'manager',
            },
        ],
    };

    useEffect(() => {
        if (location.pathname === ANALYTICS.ANALYTICS_TEAM_INSIGHTS) {
            setSelectedCohort(managerCohort.managers[0]);
        }
    }, [location.pathname]);

    return (
        <div>
            <Switch>
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_TEAM_INSIGHTS}
                    render={() => (
                        <Analytics
                            history={history}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={managerCohort.managers[0]}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            view={view}
                            setView={setView}
                            analyticsGroupBy={analyticsGroupBy}
                            setAnalyticsGroupBy={setAnalyticsGroupBy}
                            showManagerAnalytics={true}
                        />
                    )}
                />
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_DASHBOARD}
                    render={() => (
                        <Analytics
                            history={history}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            view={view}
                            setView={setView}
                            analyticsGroupBy={analyticsGroupBy}
                            setAnalyticsGroupBy={setAnalyticsGroupBy}
                        />
                    )}
                />

                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_TEAM_INSIGHTS_USERS}
                    render={() => (
                        <AnalyticsTables
                            history={history}
                            title={'Users'}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            users
                            setView={setView}
                            showManagerAnalytics={location?.state?.selectedCohort?.showManagerAnalytics}
                        />
                    )}
                />
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_USERS}
                    render={() => (
                        <AnalyticsTables
                            history={history}
                            title={'Users'}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            users
                            setView={setView}
                            showManagerAnalytics={location?.state?.selectedCohort?.showManagerAnalytics}
                        />
                    )}
                />
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_MANAGERS}
                    render={() => (
                        <AnalyticsTables
                            history={history}
                            title={'Manager Insights'}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            managers
                            setView={setView}
                        />
                    )}
                />
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_DEPARTMENTS}
                    render={() => (
                        <AnalyticsTables
                            history={history}
                            title={'Departments'}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            department
                            setView={setView}
                        />
                    )}
                />
            </Switch>
        </div>
    );
}

const mapStateToProps = ({ User }) => ({
    isAdmin: User.isAdmin,
    userId: User._id,
    userName: User.userName,
});

Index.propTypes = {
    history: PropTypes.object,
};
