export const ANALYTICS = {
    ANALYTICS_TEAM_INSIGHTS: '/dashboard/analytics/team-insights',
    ANALYTICS_TEAM_INSIGHTS_USERS: '/dashboard/analytics/team-insights/users',
    ANALYTICS_DASHBOARD: '/dashboard/analytics/report',
    ANALYTICS_USERS: '/dashboard/analytics/report/tables/users',
    ANALYTICS_MANAGERS: '/dashboard/analytics/report/tables/managers',
    ANALYTICS_DEPARTMENTS: '/dashboard/analytics/report/tables/departments',
    LEADERBOARD: '/dashboard/analytics/leaderboard',
    ALL_ACTIVITIES: '/dashboard/analytics/activities',
    MY_ACTIVITIES: '/dashboard/analytics/my-activities',
};
