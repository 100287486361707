import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from 'components/ReusableComponents/CustomDropdown';
import CustomCheckbox from 'components/Styles/CustomCheckbox';
import { Tab, Tabs } from 'react-bootstrap';
import AllUsersTab from './AllUsersTab';
import MyTeamTab from './MyTeamTab';
import { find } from 'lodash';
import clsx from 'clsx';
import './style.scss';

const Index = ({ feedLength, selectedUsers, selectedPostTypes, handleUserSelection, handlePostTypeSelection }) => {
    const [key, setKey] = useState('Users');
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);
    const [postsDropdownOpen, setPostsDropdownOpen] = useState(false);

    const POST_TYPE = ['recognition', 'celebration', 'bonus', 'award'];

    return (
        <div className={clsx('feed-header-container', { 'empty-feed': feedLength === 0 })}>
            <h1 className='feed-title'>My Feed</h1>

            <div className='container-feed-filters'>
                <CustomDropdown
                    title='Users'
                    selectedName={selectedUsers?.length > 0 ? `Selected (${selectedUsers.length})` : 'All Users'}
                    buttonWidth={220}
                    dropdownWidth={320}
                    dropdownHeight={311}
                    open={userDropdownOpen}
                    setOpen={setUserDropdownOpen}
                    buttonStyleClass='cr-dropdown-gap'
                >
                    <div className='navigatortabs cr-user-filter-tab-container'>
                        <Tabs activeKey={key} onSelect={(_key) => setKey(_key)}>
                            <Tab eventKey='Users' title='All users'>
                                <AllUsersTab
                                    selectedUsers={selectedUsers}
                                    handleUserSelection={(user) => handleUserSelection(user)}
                                />
                            </Tab>
                            <Tab eventKey='MyTeam' title='My team'>
                                <MyTeamTab
                                    selectedUsers={selectedUsers}
                                    handleUserSelection={(user) => handleUserSelection(user)}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </CustomDropdown>

                <CustomDropdown
                    title='Post Type'
                    selectedName={
                        selectedPostTypes?.length > 0 ? `Selected (${selectedPostTypes.length})` : 'All Types'
                    }
                    buttonWidth={220}
                    dropdownWidth={220}
                    dropdownHeight={170}
                    open={postsDropdownOpen}
                    setOpen={setPostsDropdownOpen}
                    buttonStyleClass='cr-dropdown-gap'
                >
                    <div className='navigatortabs cr-user-filter-tab-container'>
                        {POST_TYPE?.map((postType) => (
                            <div className='cr-user-filter-dropdown-item' key={postType}>
                                <CustomCheckbox
                                    checked={!!find(selectedPostTypes, (selectedPost) => selectedPost === postType)}
                                    onClick={() => handlePostTypeSelection(postType)}
                                />
                                <span className='post-type-dropdown-data'>{postType}</span>
                            </div>
                        ))}
                    </div>
                </CustomDropdown>
            </div>
        </div>
    );
};

Index.propTypes = {
    feedLength: PropTypes.number.isRequired,
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
    selectedPostTypes: PropTypes.array,
    handlePostTypeSelection: PropTypes.func,
};

export default Index;
