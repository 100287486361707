import React from 'react';
import { find, sortBy } from 'lodash';
import { getCountryFlagURL } from 'utils/HelperFunctions';

export const countryDetails = [
    { country: 'Afghanistan', countryCode: 'AF', currencySymbol: '؋', currencyCode: 'AFN' },
    { country: 'Albania', countryCode: 'AL', currencySymbol: 'L', currencyCode: 'ALL' },
    { country: 'Algeria', countryCode: 'DZ', currencySymbol: 'دج', currencyCode: 'DZD' },
    { country: 'Andorra', countryCode: 'AD', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Angola', countryCode: 'AO', currencySymbol: 'Kz', currencyCode: 'AOA' },
    { country: 'Anguilla', countryCode: 'AI', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Antarctica', countryCode: 'AQ' },
    { country: 'Antigua and Barbuda', countryCode: 'AG', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Argentina', countryCode: 'AR', currencyCode: 'ARS', currencySymbol: '$' },
    { country: 'Armenia', countryCode: 'AM', currencySymbol: '֏', currencyCode: 'AMD' },
    { country: 'Aruba', countryCode: 'AW', currencySymbol: 'ƒ', currencyCode: 'ANG' },
    { country: 'Australia', countryCode: 'AU', currencyCode: 'AUD', currencySymbol: '$' },
    { country: 'Austria', countryCode: 'AT', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Azerbaijan', countryCode: 'AZ', currencySymbol: '₼', currencyCode: 'AZN' },
    { country: 'Bahamas', countryCode: 'BS', currencySymbol: '$', currencyCode: 'BSD' },
    { country: 'Bahrain', countryCode: 'BH', currencyCode: 'BHD', currencySymbol: '.د.ب' },
    { country: 'Bangladesh', countryCode: 'BD', currencySymbol: '৳', currencyCode: 'BDT' },
    { country: 'Barbados', countryCode: 'BB', currencySymbol: '$', currencyCode: 'BBD' },
    { country: 'Belarus', countryCode: 'BY', currencySymbol: 'Br', currencyCode: 'BYR' },
    { country: 'Belgium', countryCode: 'BE', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Belize', countryCode: 'BZ', currencySymbol: 'BZ$', currencyCode: 'BZD' },
    { country: 'Benin', countryCode: 'BJ', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Bermuda', countryCode: 'BM', currencySymbol: '$', currencyCode: 'BMD' },
    { country: 'Bhutan', countryCode: 'BT', currencySymbol: '₹', currencyCode: 'INR' },
    { country: 'Bolivia', countryCode: 'BO', currencySymbol: '$b', currencyCode: 'BOB' },
    { country: 'Bosnia and Herzegovina', countryCode: 'BA', currencySymbol: 'KM', currencyCode: 'BAM' },
    { country: 'Botswana', countryCode: 'BW', currencySymbol: 'P', currencyCode: 'BWP' },
    { country: 'Bouvet Island', countryCode: 'BV', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Brazil', countryCode: 'BR', currencyCode: 'BRL', currencySymbol: 'R$' },
    { country: 'British Indian Ocean Territory', countryCode: 'IO', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Brunei', countryCode: 'BN', currencySymbol: '$', currencyCode: 'BND' },
    { country: 'Bulgaria', countryCode: 'BG', currencySymbol: 'лв', currencyCode: 'BGN' },
    { country: 'Burkina Faso', countryCode: 'BF', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Burundi', countryCode: 'BI', currencySymbol: 'FBu', currencyCode: 'BIF' },
    { country: 'Cambodia', countryCode: 'KH', currencySymbol: '៛', currencyCode: 'KHR' },
    { country: 'Cameroon', countryCode: 'CM', currencySymbol: 'FCFA', currencyCode: 'XAF' },
    { country: 'Canada', countryCode: 'CA', currencyCode: 'CAD', currencySymbol: '$' },
    { country: 'Cape Verde', countryCode: 'CV', currencySymbol: '$', currencyCode: 'CVE' },
    { country: 'Cayman Islands', countryCode: 'KY', currencySymbol: '$', currencyCode: 'KYD' },
    { country: 'Central African Republic', countryCode: 'CF', currencySymbol: 'FCFA', currencyCode: 'XAF' },
    { country: 'Chad', countryCode: 'TD', currencySymbol: 'FCFA', currencyCode: 'XAF' },
    { country: 'Chile', countryCode: 'CL', currencySymbol: '$', currencyCode: 'CLP' },
    { country: 'China', countryCode: 'CN', currencySymbol: '¥', currencyCode: 'CNY' },
    { country: 'Christmas Island', countryCode: 'CX', currencySymbol: '$', currencyCode: 'AUD' },
    { country: 'Cocos (Keeling) Islands', countryCode: 'CC', currencySymbol: '$', currencyCode: 'AUD' },
    { country: 'Colombia', countryCode: 'CO', currencySymbol: '$', currencyCode: 'COP' },
    { country: 'Comoros', countryCode: 'KM', currencySymbol: 'CF', currencyCode: 'KMF' },
    { country: 'Congo', countryCode: 'CG', currencySymbol: 'FCFA', currencyCode: 'XAF' },
    { country: 'Cook Islands', countryCode: 'CK', currencySymbol: '$', currencyCode: 'NZD' },
    { country: 'Costa Rica', countryCode: 'CR', currencySymbol: '₡', currencyCode: 'CRC' },
    { country: 'Croatia', countryCode: 'HR', currencySymbol: 'kn', currencyCode: 'HRK' },
    { country: 'Cuba', countryCode: 'CU', currencySymbol: '₱', currencyCode: 'CUP' },
    { country: 'Cyprus', countryCode: 'CY', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Czech Republic', countryCode: 'CZ', currencySymbol: 'Kč', currencyCode: 'CZK' },
    { country: 'Denmark', countryCode: 'DK', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Djibouti', countryCode: 'DJ', currencySymbol: 'Fdj', currencyCode: 'DJF' },
    { country: 'Dominica', countryCode: 'DM', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Dominican Republic', countryCode: 'DO', currencySymbol: 'RD$', currencyCode: 'DOP' },
    { country: 'East Timor', countryCode: 'TP', currencySymbol: 'Rp', currencyCode: 'IDR' },
    { country: 'Ecuador', countryCode: 'EC', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Egypt', countryCode: 'EG', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'El Salvador', countryCode: 'SV', currencySymbol: '$', currencyCode: 'SVC' },
    { country: 'Equatorial Guinea', countryCode: 'GQ', currencySymbol: 'FCFA', currencyCode: 'XAF' },
    { country: 'Eritrea', countryCode: 'ER', currencySymbol: 'Br', currencyCode: 'BYR' },
    { country: 'Estonia', countryCode: 'EE', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Ethiopia', countryCode: 'ET', currencySymbol: 'Br', currencyCode: 'BYR' },
    { country: 'Falkland Islands', countryCode: 'FK', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Faroe Islands', countryCode: 'FO', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Fiji Islands', countryCode: 'FJ', currencySymbol: '$', currencyCode: 'FJD' },
    { country: 'Finland', countryCode: 'FI', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'France', countryCode: 'FR', currencyCode: 'EUR', currencySymbol: '€' },
    { country: 'French Guiana', countryCode: 'GF', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'French Polynesia', countryCode: 'PF', currencySymbol: '₣', currencyCode: 'XPF' },
    { country: 'French Southern territories', countryCode: 'TF', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Gabon', countryCode: 'GA', currencySymbol: 'FCFA', currencyCode: 'XAF' },
    { country: 'Gambia', countryCode: 'GM', currencySymbol: 'D', currencyCode: 'GMD' },
    { country: 'Georgia', countryCode: 'GE', currencySymbol: '₾', currencyCode: 'GEL' },
    { country: 'Germany', countryCode: 'DE', currencyCode: 'EUR', currencySymbol: '€' },
    { country: 'Ghana', countryCode: 'GH', currencySymbol: 'GH₵', currencyCode: 'GHS' },
    { country: 'Gibraltar', countryCode: 'GI', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Greece', countryCode: 'GR', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Greenland', countryCode: 'GL', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Grenada', countryCode: 'GD', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Guadeloupe', countryCode: 'GP', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Guam', countryCode: 'GU', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Guatemala', countryCode: 'GT', currencySymbol: 'Q', currencyCode: 'GTQ' },
    { country: 'Guernsey', countryCode: 'GG', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Guinea', countryCode: 'GN', currencySymbol: 'FG', currencyCode: 'GNF' },
    { country: 'Guinea-Bissau', countryCode: 'GW', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Guyana', countryCode: 'GY', currencySymbol: '$', currencyCode: 'GYD' },
    { country: 'Haiti', countryCode: 'HT', currencySymbol: 'G', currencyCode: 'HTG' },
    { country: 'Heard Island and McDonald Islands', countryCode: 'HM', currencySymbol: '$', currencyCode: 'AUD' },
    { country: 'Holy See (Vatican City State)', countryCode: 'VA', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Honduras', countryCode: 'HN', currencySymbol: 'L', currencyCode: 'ALL' },
    { country: 'Hong Kong', countryCode: 'HK', currencyCode: 'HKD', currencySymbol: '$' },
    { country: 'Hungary', countryCode: 'HU', currencySymbol: 'Ft', currencyCode: 'HUF' },
    { country: 'Iceland', countryCode: 'IS', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'India', countryCode: 'IN', currencyCode: 'INR', currencySymbol: '₹' },
    { country: 'Indonesia', countryCode: 'ID', currencySymbol: 'Rp', currencyCode: 'IDR' },
    { country: 'Iran', countryCode: 'IR', currencySymbol: '﷼', currencyCode: 'IRR' },
    { country: 'Iraq', countryCode: 'IQ', currencySymbol: 'ع.د', currencyCode: 'IQD' },
    { country: 'Ireland', countryCode: 'IE', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Isle of Man', countryCode: 'IM', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Israel', countryCode: 'IL', currencySymbol: '₪', currencyCode: 'ILS' },
    { country: 'Italy', countryCode: 'IT', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Ivory Coast', countryCode: 'CI', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Jamaica', countryCode: 'JM', currencySymbol: 'J$', currencyCode: 'JMD' },
    { country: 'Japan', countryCode: 'JP', currencySymbol: '¥', currencyCode: 'CNY' },
    { country: 'Jersey', countryCode: 'JE', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Jordan', countryCode: 'JO', currencySymbol: 'JD', currencyCode: 'JOD' },
    { country: 'Kazakhstan', countryCode: 'KZ', currencySymbol: 'лв', currencyCode: 'BGN' },
    { country: 'Kenya', countryCode: 'KE', currencySymbol: 'KSh', currencyCode: 'KES' },
    { country: 'Kiribati', countryCode: 'KI', currencySymbol: '$', currencyCode: 'AUD' },
    { country: 'Kuwait', countryCode: 'KW', currencySymbol: 'KD', currencyCode: 'KWD' },
    { country: 'Kyrgyzstan', countryCode: 'KG', currencySymbol: 'лв', currencyCode: 'BGN' },
    { country: 'Laos', countryCode: 'LA', currencySymbol: '₭', currencyCode: 'LAK' },
    { country: 'Latvia', countryCode: 'LV', currencySymbol: 'Ls', currencyCode: 'LVL' },
    { country: 'Lebanon', countryCode: 'LB', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Lesotho', countryCode: 'LS', currencySymbol: 'M', currencyCode: 'LSL' },
    { country: 'Liberia', countryCode: 'LR', currencySymbol: '$', currencyCode: 'LRD' },
    { country: 'Libya', countryCode: 'LY', currencySymbol: 'LD', currencyCode: 'LYD' },
    { country: 'Liechtenstein', countryCode: 'LI', currencySymbol: 'CHF', currencyCode: 'CHF' },
    { country: 'Lithuania', countryCode: 'LT', currencySymbol: 'Lt', currencyCode: 'LTL' },
    { country: 'Luxembourg', countryCode: 'LU', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Macao', countryCode: 'MO', currencySymbol: 'MOP$', currencyCode: 'MOP' },
    { country: 'North Macedonia', countryCode: 'MK', currencySymbol: 'ден', currencyCode: 'MKD' },
    { country: 'Madagascar', countryCode: 'MG', currencySymbol: 'Ar', currencyCode: 'MGA' },
    { country: 'Malawi', countryCode: 'MW', currencySymbol: 'MK', currencyCode: 'MWK' },
    { country: 'Malaysia', countryCode: 'MY', currencySymbol: 'RM', currencyCode: 'MYR' },
    { country: 'Maldives', countryCode: 'MV', currencySymbol: 'Rf', currencyCode: 'MVR' },
    { country: 'Mali', countryCode: 'ML', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Malta', countryCode: 'MT', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Marshall Islands', countryCode: 'MH', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Martinique', countryCode: 'MQ', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Mauritania', countryCode: 'MR', currencySymbol: 'UM', currencyCode: 'MRO' },
    { country: 'Mauritius', countryCode: 'MU', currencySymbol: '₨', currencyCode: 'LKR' },
    { country: 'Mayotte', countryCode: 'YT', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Mexico', countryCode: 'MX', currencySymbol: '$', currencyCode: 'MXN' },
    { country: 'Micronesia, Federated States of', countryCode: 'FM', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Moldova', countryCode: 'MD', currencySymbol: 'lei', currencyCode: 'MDL' },
    { country: 'Monaco', countryCode: 'MC', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Mongolia', countryCode: 'MN', currencySymbol: '₮', currencyCode: 'MNT' },
    { country: 'Montenegro', countryCode: 'ME', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Montserrat', countryCode: 'MS', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Morocco', countryCode: 'MA', currencySymbol: 'MAD', currencyCode: 'MAD' },
    { country: 'Mozambique', countryCode: 'MZ', currencySymbol: 'MT', currencyCode: 'MZN' },
    { country: 'Myanmar', countryCode: 'MM', currencySymbol: 'K', currencyCode: 'MMK' },
    { country: 'Namibia', countryCode: 'NA', currencySymbol: '$', currencyCode: 'NAD' },
    { country: 'Nauru', countryCode: 'NR', currencySymbol: '$', currencyCode: 'AUD' },
    { country: 'Nepal', countryCode: 'NP', currencySymbol: '₨', currencyCode: 'LKR' },
    { country: 'Netherlands', countryCode: 'NL', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Netherlands Antilles', countryCode: 'AN', currencySymbol: 'ƒ', currencyCode: 'ANG' },
    { country: 'New Caledonia', countryCode: 'NC', currencySymbol: '₣', currencyCode: 'XPF' },
    { country: 'New Zealand', countryCode: 'NZ', currencySymbol: '$', currencyCode: 'NZD' },
    { country: 'Nicaragua', countryCode: 'NI', currencySymbol: 'C$', currencyCode: 'NIO' },
    { country: 'Niger', countryCode: 'NE', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Nigeria', countryCode: 'NG', currencySymbol: '₦', currencyCode: 'NGN' },
    { country: 'Niue', countryCode: 'NU', currencySymbol: '$', currencyCode: 'NZD' },
    { country: 'Norfolk Island', countryCode: 'NF', currencySymbol: '$', currencyCode: 'AUD' },
    { country: 'North Korea', countryCode: 'KP', currencySymbol: '₩', currencyCode: 'KPW' },
    { country: 'Northern Ireland', countryCode: 'GB', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Northern Mariana Islands', countryCode: 'MP', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Norway', countryCode: 'NO', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Oman', countryCode: 'OM', currencySymbol: '﷼', currencyCode: 'IRR' },
    { country: 'Pakistan', countryCode: 'PK', currencySymbol: '₨', currencyCode: 'LKR' },
    { country: 'Palau', countryCode: 'PW', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Palestine', countryCode: 'PS', currencySymbol: 'JD', currencyCode: 'JOD' },
    { country: 'Panama', countryCode: 'PA', currencySymbol: 'B/.', currencyCode: 'PAB' },
    { country: 'Papua New Guinea', countryCode: 'PG', currencySymbol: 'K', currencyCode: 'MMK' },
    { country: 'Paraguay', countryCode: 'PY', currencySymbol: 'Gs', currencyCode: 'PYG' },
    { country: 'Peru', countryCode: 'PE', currencySymbol: 'S/.', currencyCode: 'PEN' },
    { country: 'Philippines', countryCode: 'PH', currencySymbol: '₱', currencyCode: 'PHP' },
    { country: 'Pitcairn', countryCode: 'PN', currencySymbol: '$', currencyCode: 'NZD' },
    { country: 'Poland', countryCode: 'PL', currencySymbol: 'zł', currencyCode: 'PLN' },
    { country: 'Portugal', countryCode: 'PT', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Puerto Rico', countryCode: 'PR', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Qatar', countryCode: 'QA', currencySymbol: '﷼', currencyCode: 'IRR' },
    { country: 'Romania', countryCode: 'RO', currencySymbol: 'lei', currencyCode: 'RON' },
    { country: 'Russian Federation', countryCode: 'RU', currencySymbol: '₽', currencyCode: 'RUB' },
    { country: 'Rwanda', countryCode: 'RW', currencySymbol: 'R₣', currencyCode: 'RWF' },
    { country: 'Saint Helena', countryCode: 'SH', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Saint Kitts and Nevis', countryCode: 'KN', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Saint Lucia', countryCode: 'LC', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Saint Pierre and Miquelon', countryCode: 'PM', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Saint Vincent and the Grenadines', countryCode: 'VC', currencySymbol: '$', currencyCode: 'XCD' },
    { country: 'Samoa', countryCode: 'WS', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'San Marino', countryCode: 'SM', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Sao Tome and Principe', countryCode: 'ST', currencySymbol: 'Db', currencyCode: 'STD' },
    { country: 'Saudi Arabia', countryCode: 'SA', currencySymbol: '﷼', currencyCode: 'IRR' },
    { country: 'Senegal', countryCode: 'SN', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Serbia', countryCode: 'RS', currencySymbol: 'Дин.', currencyCode: 'RSD' },
    { country: 'Seychelles', countryCode: 'SC', currencySymbol: '₨', currencyCode: 'LKR' },
    { country: 'Sierra Leone', countryCode: 'SL', currencySymbol: 'Le', currencyCode: 'SLL' },
    { country: 'Singapore', countryCode: 'SG', currencySymbol: '$', currencyCode: 'SGD' },
    { country: 'Slovakia', countryCode: 'SK', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Slovenia', countryCode: 'SI', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Solomon Islands', countryCode: 'SB', currencySymbol: '$', currencyCode: 'SBD' },
    { country: 'Somalia', countryCode: 'SO', currencySymbol: 'S', currencyCode: 'SOS' },
    { country: 'South Africa', countryCode: 'ZA', currencySymbol: 'R', currencyCode: 'ZAR' },
    {
        country: 'South Georgia and the South Sandwich Islands',
        countryCode: 'GS',
        currencySymbol: '£',
        currencyCode: 'EGP',
    },
    { country: 'South Korea', countryCode: 'KR', currencySymbol: '₩', currencyCode: 'KPW' },
    { country: 'South Sudan', countryCode: 'SS', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Spain', countryCode: 'ES', currencySymbol: '€', currencyCode: 'EUR' },
    { country: 'Sri Lanka', countryCode: 'LK', currencySymbol: '₨', currencyCode: 'LKR' },
    { country: 'Sudan', countryCode: 'SD', currencySymbol: 'ج.س.', currencyCode: 'SDG' },
    { country: 'Suriname', countryCode: 'SR', currencySymbol: '$', currencyCode: 'SRD' },
    { country: 'Svalbard and Jan Mayen', countryCode: 'SJ', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Swaziland', countryCode: 'SZ', currencySymbol: 'E', currencyCode: 'SZL' },
    { country: 'Sweden', countryCode: 'SE', currencySymbol: 'kr', currencyCode: 'DKK' },
    { country: 'Switzerland', countryCode: 'CH', currencySymbol: 'CHF', currencyCode: 'CHF' },
    { country: 'Syria', countryCode: 'SY', currencySymbol: '£', currencyCode: 'EGP' },
    { country: 'Tajikistan', countryCode: 'TJ', currencySymbol: 'SM', currencyCode: 'TJS' },
    { country: 'Tanzania', countryCode: 'TZ', currencySymbol: 'TSh', currencyCode: 'TZS' },
    { country: 'Thailand', countryCode: 'TH', currencySymbol: '฿', currencyCode: 'THB' },
    { country: 'The Democratic Republic of Congo', countryCode: 'CD', currencySymbol: 'FC', currencyCode: 'CDF' },
    { country: 'Timor-Leste', countryCode: 'TL', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Taiwan', countryCode: 'TW', currencySymbol: 'NT$', currencyCode: 'TWD' },
    { country: 'Togo', countryCode: 'TG', currencySymbol: 'CFA', currencyCode: 'XOF' },
    { country: 'Tokelau', countryCode: 'TK', currencySymbol: '$', currencyCode: 'NZD' },
    { country: 'Tonga', countryCode: 'TO', currencySymbol: 'T$', currencyCode: 'TOP' },
    { country: 'Trinidad and Tobago', countryCode: 'TT', currencySymbol: 'TT$', currencyCode: 'TTD' },
    { country: 'Tunisia', countryCode: 'TN', currencySymbol: 'د.ت', currencyCode: 'TND' },
    { country: 'Turkey', countryCode: 'TR', currencySymbol: '₺', currencyCode: 'TRY' },
    { country: 'Turkmenistan', countryCode: 'TM', currencySymbol: 'T', currencyCode: 'TMT' },
    { country: 'Turks and Caicos Islands', countryCode: 'TC', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Tuvalu', countryCode: 'TV', currencySymbol: '$', currencyCode: 'AUD' },
    { country: 'Uganda', countryCode: 'UG', currencySymbol: 'USh', currencyCode: 'UGX' },
    { country: 'Ukraine', countryCode: 'UA', currencySymbol: '₴', currencyCode: 'UAH' },
    { country: 'United Arab Emirates', countryCode: 'AE', currencySymbol: 'د.إ', currencyCode: 'AED' },
    { country: 'United Kingdom', countryCode: 'UK', currencySymbol: '£', currencyCode: 'GBP' },
    {
        country: 'United States Minor Outlying Islands',
        countryCode: 'UM',
        currencySymbol: '$',
        currencyCode: 'USD',
    },
    { country: 'United States of America', countryCode: 'USA', currencyCode: 'USD', currencySymbol: '$' },
    { country: 'Uruguay', countryCode: 'UY', currencySymbol: '$U', currencyCode: 'UYU' },
    { country: 'Uzbekistan', countryCode: 'UZ', currencySymbol: 'лв', currencyCode: 'UZS' },
    { country: 'Vanuatu', countryCode: 'VU', currencySymbol: 'VT', currencyCode: 'VUV' },
    { country: 'Venezuela', countryCode: 'VE', currencySymbol: 'Bs', currencyCode: 'VEF' },
    { country: 'Vietnam', countryCode: 'VN', currencySymbol: '₫', currencyCode: 'VND' },
    { country: 'Virgin Islands, British', countryCode: 'VG', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Virgin Islands, U.S.', countryCode: 'VI', currencySymbol: '$', currencyCode: 'USD' },
    { country: 'Wallis and Futuna', countryCode: 'WF', currencySymbol: '₣', currencyCode: 'XPF' },
    { country: 'Western Sahara', countryCode: 'EH', currencySymbol: 'MAD', currencyCode: 'MAD' },
    { country: 'Yemen', countryCode: 'YE', currencySymbol: '﷼', currencyCode: 'YER' },
    { country: 'Zambia', countryCode: 'ZM', currencySymbol: 'ZK', currencyCode: 'ZMW' },
    { country: 'Zimbabwe', countryCode: 'ZW', currencySymbol: 'Z$', currencyCode: 'ZWD' },
];

export const getCountries = (currencyLabel = false, callingCode = false) => {
    const result = countryDetails.map((country) => ({
        ...country,
        countryFlag: getCountryFlagURL(country.country),
        currency: country.currencyCode,
        label: callingCode ? (
            <p className='country-selection-filter mb-0'>
                <img src={getCountryFlagURL(country.country)} alt='' />
                &nbsp;
                {country.callingCode}
            </p>
        ) : (
            <p className='country-selection-filter mb-0'>
                <img src={getCountryFlagURL(country.country)} alt='' />
                &nbsp;
                {currencyLabel ? `${country.country} (${country.currencyCode})` : country?.country}
            </p>
        ),
        value: country?.country,
    }));
    return sortBy(result, 'country');
};

/**
 * Get the details of the country
 * @param {string} country Name of the country
 */
export const getCountryDetails = (country) => {
    return find(countryDetails, (item) => item.country === country);
};

export const getCountryNameByCode = (countryCode) => {
    return find(countryDetails, (item) => item.countryCode === countryCode);
};
