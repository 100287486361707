import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import { isSubscriptionCancelled } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ history, title, buttonLabel, targetRoute, Icon, currentRoute, backgroundColor }) => {
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const { subscription } = useSelector(mapStateToProps, shallowEqual);

    const handleClick = () => {
        if (isSubscriptionCancelled(subscription)) {
            setSubscriptionCancelled(true);
            return;
        }
        history.push(targetRoute);
    };

    return (
        <div className='banner-container' style={{ backgroundColor }}>
            <div className='d-flex'>
                <Icon className='mr-5' />
                <h5 className='title'>{title}</h5>
            </div>
            <button className='ew-btn btn-wrapper' style={{ color: backgroundColor }} onClick={handleClick}>
                {buttonLabel}
            </button>
            <SubscriptionCancelledModal
                open={subscriptionCancelled}
                onClose={() => setSubscriptionCancelled(false)}
                history={history}
                currentRoute={currentRoute}
            />
        </div>
    );
};
Index.propTypes = {
    history: PropTypes.object.isRequired,
    title: PropTypes.node.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    targetRoute: PropTypes.string.isRequired,
    Icon: PropTypes.elementType,
    currentRoute: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
};
const mapStateToProps = ({ Payments }) => ({
    subscription: Payments?.subscription,
});

export default Index;
