import React from 'react';
import EWButton from 'components/ReusableComponents/EWButton';
import PropTypes from 'prop-types';
import '../styles.scss';

const Index = ({ data, history }) => {
    return (
        <div className=' events-container'>
            <div className='content'>
                <p className='event-description'>
                    Voting period has been ended for <span className='award-name-text'>{data.name}</span> award, please
                    announce the winner.
                </p>
            </div>
            <div className='footer'>
                <EWButton buttonStyleClass='events-action-btn'>Manage</EWButton>
            </div>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
};

export default Index;
