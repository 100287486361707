import React from 'react';
import PropTypes from 'prop-types';
import { makePlural } from 'utils/HelperFunctions';
import { CELEBRATION_TYPE, POST_TYPE } from 'components/Dashboard/Content/Home/constants';
import './style.scss';

const Index = ({ recognitionData, showDownArrowIcon = true, isAddOn = false }) => {
    const { sender, receivers, type, feedSubType, employeeAward, recognition, rewardName } = isAddOn
        ? { ...recognitionData, recognition: recognitionData }
        : recognitionData;

    const getHeaderImage = () => {
        if (type === POST_TYPE.CELEBRATION || type === POST_TYPE.BONUS || type === POST_TYPE.AWARD) {
            return receivers[0].pictureURL ?? require('Assets/images/defaultUser.png');
        }

        return sender.pictureURL ?? require('Assets/images/defaultUser.png');
    };

    const getUserNames = (receivers) => {
        const userNames = receivers.map((receiver) => receiver.userName);
        let names = '';
        if (userNames.length === 1) {
            names = userNames[0];
        } else if (userNames.length === 2) {
            names = userNames.join(' and ');
        } else if (userNames.length > 2) {
            names = `${userNames.slice(0, 2).join(',')}  and ${userNames.length - 2} more`;
        }
        return names;
    };

    const getHeaderText = () => {
        switch (type) {
            case POST_TYPE.RECOGNITION:
                return (
                    <span>
                        <strong className='mr-1'>{sender.userName}</strong>gave
                        <strong className='ml-1'>{recognition?.rewardName ?? rewardName}</strong>&nbsp;to{' '}
                        {showDownArrowIcon && !isAddOn ? '⬇️' : ''}
                    </span>
                );

            case POST_TYPE.CELEBRATION:
                return (
                    <span>
                        <strong>{receivers[0].userName}</strong>
                        &nbsp;
                        {receivers?.length > 1
                            ? `and ${makePlural(receivers.length - 1, 'other')} are celebrating their`
                            : 'is celebrating their'}
                        &nbsp;
                        <span style={{ fontWeight: 500 }}>
                            {feedSubType === CELEBRATION_TYPE.BIRTHDAY ? CELEBRATION_TYPE.BIRTHDAY : 'work anniversary'}
                        </span>
                    </span>
                );

            case POST_TYPE.BONUS:
                return (
                    <span className='mr-2'>
                        <strong>{getUserNames(receivers)}</strong>&nbsp;got a bonus
                    </span>
                );

            case POST_TYPE.AWARD:
                return (
                    <span className='mr-2'>
                        <strong>{receivers[0]?.userName}</strong>
                        &nbsp;
                        {receivers?.length > 1 && `and ${makePlural(receivers?.length - 1, 'other')}`}
                        received&nbsp;
                        <strong>"{employeeAward.awardName}"</strong> award
                    </span>
                );

            default:
                return null;
        }
    };

    return (
        <div className='recognition-header-user'>
            <img width={22} height={22} className='user-image' src={getHeaderImage()} alt='' />
            {getHeaderText()}
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.shape({
        sender: PropTypes.shape({
            userName: PropTypes.string,
            pictureURL: PropTypes.string,
        }),
        receivers: PropTypes.arrayOf(
            PropTypes.shape({
                userName: PropTypes.string,
                pictureURL: PropTypes.string,
            })
        ),
        type: PropTypes.string,
        feedSubType: PropTypes.string,
        employeeAward: PropTypes.shape({
            awardName: PropTypes.string,
        }),
        recognition: PropTypes.shape({
            rewardName: PropTypes.string,
        }),
        rewardName: PropTypes.string,
        message: PropTypes.string,
    }).isRequired,
    showDownArrowIcon: PropTypes.bool,
    isAddOn: PropTypes.bool,
};

export default Index;
