import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as BonusBanner } from 'Assets/images/bonus-post-banner.svg';
import { ReactComponent as BirthdayBanner } from 'Assets/images/birthday-post-banner.svg';
import { ReactComponent as AnniversaryBanner } from 'Assets/images/anniversary-post-banner.svg';
import { ReactComponent as QuoteIcon } from 'Assets/images/quote.svg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CELEBRATION_TYPE, POST_APPRECIATION_MESSAGE, POST_TYPE, RECEIVERS_TO_SHOW } from '../../constants';
import { makePlural } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ recognitionData }) => {
    const receiversToShow = recognitionData?.receivers.slice(0, RECEIVERS_TO_SHOW);
    const isBonusPost = recognitionData?.type === POST_TYPE.BONUS;
    const isBirthdayPost =
        recognitionData?.type === POST_TYPE.CELEBRATION && recognitionData?.feedSubType === CELEBRATION_TYPE.BIRTHDAY;
    const isAnniversaryPost =
        recognitionData?.type === POST_TYPE.CELEBRATION &&
        recognitionData?.feedSubType === CELEBRATION_TYPE.WORK_ANNIVERSARY;

    const getPostAppreciationMessage = () => {
        if (isBonusPost && recognitionData?.message) {
            return recognitionData?.message;
        }
        return POST_APPRECIATION_MESSAGE;
    };

    return (
        <div
            className={clsx('other-posts-container', {
                'bonus-post-container': isBonusPost,
                'birthday-post-container': isBirthdayPost,
                'anniversary-post-container': isAnniversaryPost,
            })}
        >
            {isBonusPost && <BonusBanner />}
            {isBirthdayPost && <BirthdayBanner />}
            {isAnniversaryPost && <AnniversaryBanner />}

            {isAnniversaryPost && (
                <div className='anniversary-years'>
                    <p className='year-amount'>{recognitionData?.years}</p>
                    <p className='year-text'>{makePlural(recognitionData?.years, 'Year', true)}</p>
                </div>
            )}

            <div
                className={clsx('receivers-data-container', {
                    'bonus-data-container': isBonusPost,
                    'birthday-data-container': isBirthdayPost,
                    'anniversary-data-container': isAnniversaryPost,
                })}
            >
                <div className='receivers-image-container'>
                    {receiversToShow.map((receiver) => (
                        <img
                            key={receiver._id}
                            src={receiver.pictureURL || require('Assets/images/defaultUser.png')}
                            alt=''
                            className='receiver-avatar'
                        />
                    ))}
                    {recognitionData?.receivers.length > RECEIVERS_TO_SHOW && (
                        <div
                            className={clsx('receiver-avatar extra-receivers', {
                                'bonus-extra-receivers': isBonusPost,
                                'birthday-extra-receivers': isBirthdayPost,
                                'anniversary-extra-receivers': isAnniversaryPost,
                            })}
                        >
                            + {recognitionData?.receivers.length - RECEIVERS_TO_SHOW}
                        </div>
                    )}
                </div>

                <div
                    className={clsx('receivers-name-container', {
                        'padding-more-receivers': receiversToShow.length > 4,
                        'padding-less-receiers': receiversToShow.length <= 4,
                    })}
                >
                    {receiversToShow.map((receiver, idx) => (
                        <span key={receiver._id} className='receiver-name'>
                            {receiver.userName}{' '}
                            {idx < receiversToShow.length - 1 && <FiberManualRecordIcon className='separator-icon' />}
                        </span>
                    ))}
                    {recognitionData?.receivers.length > RECEIVERS_TO_SHOW && (
                        <span className='receiver-name'>
                            <FiberManualRecordIcon className='separator-icon' /> +{' '}
                            {recognitionData?.receivers.length - RECEIVERS_TO_SHOW} more
                        </span>
                    )}
                </div>
            </div>

            <div className='appreciation-text-container'>
                <QuoteIcon className='quote-icon' />
                <p className='appreciation-text'>{getPostAppreciationMessage()}</p>
            </div>
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
};

export default Index;
