import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as SuccessCheckMark } from 'Assets/images/success-checkmark.svg';
import './style.scss';

const Index = ({ open, setOpen, data, onConfirm }) => {
    if (!open) {
        return null;
    }

    return (
        <Modal open={open} onClose={setOpen}>
            <div className='success-created-modal'>
                <CloseIcon className='btn-close' onClick={() => setOpen()} data-testid='close' />
                <div className='content d-flex flex-column justify-content-center align-items-center'>
                    <SuccessCheckMark />
                    <p className='success-header'>{data?.content}</p>
                </div>
                <span className='note'>
                    <strong>Note: </strong>
                    {data?.note}
                </span>
                <button className='ew-btn ob-btn' onClick={() => onConfirm()}>
                    {data?.buttonText}
                </button>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.shape({
        content: PropTypes.string,
        note: PropTypes.string,
        buttonText: PropTypes.string,
    }),
    onConfirm: PropTypes.func,
};

export default Index;
