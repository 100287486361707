import React from 'react';
import PropTypes from 'prop-types';
import MemberTag from 'components/ReusableComponents/MemberTag';
import EWButton from 'components/ReusableComponents/EWButton';
import '../styles.scss';

const Index = ({ data, history }) => {
    return (
        <div className='events-container'>
            <div className='content new-awards-content'>
                <p className='event-description'>
                    <MemberTag
                        memberID={data?.createdBy?._id}
                        memberName={data?.createdBy?.name}
                        memberPhoto={data?.createdBy?.pictureURL}
                    />
                    {' launched '}
                    <span className='award-name-text'>{data?.name}</span>
                    {' with '}
                    <span className='nominiees-count-text'>{data?.nomineesCount} nominees</span>
                    {', set the ball rolling and cast your vote.'}
                </p>
            </div>
            <div className='footer'>
                <EWButton buttonStyleClass='events-action-btn'>Vote now</EWButton>
            </div>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
};

export default Index;
